var _a, _b;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
import { Colors, MediaBreakPointDown, MediaBreakPointUp } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router';
import { SettingStyles } from '../pages/main_setting';
import { ContractPlan } from '../atoms/contract_plan';
import { ArrowDirection, ArrowIcon } from '../atoms';
import { dateToYearMonthDayString } from '@lean-body/src/util';
import { CancelUnsubscribeConfirmDialog } from '../molecules/cancel_unsubscribe_confirm_dialog';
import { RemoveQueuedPlanModal } from './remove_queued_plan_modal';
import dayjs from 'dayjs';
export var StripeAmazonPayPlanSection = function (_a) {
    var _b;
    var classObject = _a.classObject, me = _a.me, isQueuedPlanForPricingRevision = _a.isQueuedPlanForPricingRevision, onRemoveQueuedPlan = _a.onRemoveQueuedPlan, onCancelUnsubscribe = _a.onCancelUnsubscribe;
    var navigate = useNavigate();
    var payment = me.payment;
    var coachingPayment = me.payment.coachingPayment;
    var currentPlan = me.payment.currentPlan;
    var queuedPlan = me.payment.queuedPlan;
    var isDeleted = !!me.deletedAt;
    var _c = React.useState(false), showCancelUnsubscribeDialog = _c[0], setShowCancelUnsubscribeDialog = _c[1];
    var canRemoveQueuedPlanModal = function () {
        if (!queuedPlan)
            return false;
        if (!currentPlan.canCancelPlanChanging())
            return false;
        // 価格改訂のためのqueued_planの場合、プラン変更は取り消せない
        if (isQueuedPlanForPricingRevision)
            return false;
        return true;
    };
    var isUnsubscribeButtonVisible = function () {
        return !isDeleted && !me.isDormant;
    };
    // コーチングの終了日を返却
    var coachingEndDate = function () {
        if (me.unsubscribeScheduledAt && coachingPayment.deleted) {
            // LBとコーチング両方退会した場合、早く終わる方の日付を返す
            return dayjs(payment.curPeriodEndDate).isBefore(coachingPayment.curPeriodEndDate)
                ? payment.curPeriodEndDate
                : coachingPayment.curPeriodEndDate;
        }
        else if (me.unsubscribeScheduledAt && !coachingPayment.deleted) {
            // LBだけ退会した場合、LBの終了日を返す
            return payment.curPeriodEndDate;
        }
        else if (!me.unsubscribeScheduledAt && coachingPayment.deleted) {
            // コーチングだけ退会した場合、コーチングの終了日を返す
            return coachingPayment.curPeriodEndDate;
        }
    };
    var onClickShowCancelUnsubscribeDialog = function () {
        setShowCancelUnsubscribeDialog(true);
    };
    var onCloseCancelUnsubscribeDialog = function () {
        setShowCancelUnsubscribeDialog(false);
    };
    var onSuccessCancelUnsubscribeDialog = function () {
        setShowCancelUnsubscribeDialog(false);
        onCancelUnsubscribe();
    };
    return (React.createElement(React.Fragment, null, isDeleted ? (React.createElement("div", { className: css(styles.isDeletedContainer, classObject) },
        "\u9000\u4F1A\u6E08\u307F\u3002",
        dateToYearMonthDayString(payment.curPeriodEndDate),
        "\u307E\u3067\u30B5\u30FC\u30D3\u30B9\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002\u305D\u306E\u5F8C\u306F\u30B5\u30FC\u30D3\u30B9\u3092\u3054\u5229\u7528\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059\u3002",
        me.payment.coachingPayment &&
            !me.payment.isExpirationDateNearThanCoachingPayment(payment.coachingPayment) && (React.createElement(React.Fragment, null,
            React.createElement("br", null),
            "\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u306F",
            dateToYearMonthDayString(payment.coachingPayment.curPeriodEndDate),
            "\u307E\u3067\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002")))) : (React.createElement("div", { className: css(styles.container, classObject), "data-test": "planContainer" },
        React.createElement("div", { className: css(styles.planTitle) }, !me.unsubscribeScheduledAt && queuedPlan && 'ご利用中のプラン'),
        React.createElement("div", null,
            React.createElement(ContractPlan, { name: currentPlan.name, priceWithTax: currentPlan.amount.toLocaleString(), updateDate: !queuedPlan && payment.curPeriodEndDate, endDate: me.unsubscribeScheduledAt && payment.curPeriodEndDate }),
            !me.unsubscribeScheduledAt && queuedPlan && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.planTitleNext) }, "\u66F4\u65B0\u4E88\u5B9A\u306E\u30D7\u30E9\u30F3"),
                React.createElement(ContractPlan, { name: queuedPlan.name, priceWithTax: queuedPlan.amount.toLocaleString(), updateDate: payment.curPeriodEndDate, updateDateClassObject: styles.queuedPlanUpdateDate }))),
            React.createElement("div", { className: css(styles.planButtons) }, me.unsubscribeScheduledAt ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: css(SettingStyles.button, styles.cancelUnsubscribeButton), onClick: onClickShowCancelUnsubscribeDialog, buttonType: ButtonType.primary }, "\u9000\u4F1A\u3092\u53D6\u308A\u6D88\u3059"))) : (React.createElement(React.Fragment, null,
                me.canChangePlans && (React.createElement(Button, { className: css(SettingStyles.button), buttonType: ButtonType.whiteWithBlack, onClick: function () { return navigate(routes.PLAN_CHANGE.compile()); }, dataTest: "changePlan" }, "\u30D7\u30E9\u30F3\u3092\u5909\u66F4\u3059\u308B")),
                canRemoveQueuedPlanModal() && (React.createElement(RemoveQueuedPlanModal, { className: css(SettingStyles.button), onClick: onRemoveQueuedPlan })),
                me.isDormantAvailable && (React.createElement(React.Fragment, null, ((_b = payment.coachingPayment) === null || _b === void 0 ? void 0 : _b.isOngoing) ? (React.createElement("div", null,
                    React.createElement(Button, { buttonType: ButtonType.whiteWithBlack, className: css(SettingStyles.button, styles.disabledButton), disabled: true }, "\u4F11\u7720\u3059\u308B"),
                    React.createElement("div", { className: css(styles.dormantAnnotation) }, "\u203B\u4F11\u7720\u3059\u308B\u306B\u306F\u30D5\u30A3\u30C3\u30C8\u30E9\u30A4\u30D5\u30B3\u30FC\u30C1\u30D7\u30E9\u30F3\u3092\u505C\u6B62\u3057\u3066\u304F\u3060\u3055\u3044"))) : (React.createElement(Button, { buttonType: ButtonType.whiteWithBlack, className: css(SettingStyles.button), onClick: function () { return navigate(routes.DORMANT.compile()); } }, "\u4F11\u7720\u3059\u308B")))),
                isUnsubscribeButtonVisible() && (React.createElement(Button, { className: css(SettingStyles.button, styles.unsubscribeButton), onClick: function () { return navigate(routes.UNSUBSCRIBE_CAUTION.compile()); }, dataTest: "deleteAccount", buttonType: ButtonType.whiteWithBlack },
                    "\u9000\u4F1A\u3059\u308B",
                    React.createElement(ArrowIcon, { classObject: styles.unsubscribeButtonArrow, color: Colors.Black, direction: ArrowDirection.right }))))))),
        (coachingPayment === null || coachingPayment === void 0 ? void 0 : coachingPayment.isValid) && (React.createElement(React.Fragment, null,
            React.createElement("hr", { className: css(styles.coachingPlanDivider) }),
            React.createElement("div", null,
                React.createElement(ContractPlan, { name: coachingPayment.planName, priceWithTax: coachingPayment.planPrice.toLocaleString(), updateDate: coachingPayment.curPeriodEndDate, endDate: coachingEndDate(), endDateReason: me.unsubscribeScheduledAt && !coachingPayment.deleted && '退会手続き済みのため' }),
                !coachingPayment.deleted && (React.createElement("div", { className: css(styles.planButtons) },
                    React.createElement(Button, { className: css(SettingStyles.button, styles.unsubscribeButton), buttonType: ButtonType.whiteWithBlack, onClick: function () { return navigate(routes.COACHING_PLAN_UNSUBSCRIBE.compile()); }, dataTest: "changePlan" },
                        "\u30D5\u30A3\u30C3\u30C8\u30E9\u30A4\u30D5\u30B3\u30FC\u30C1\u3092\u9000\u4F1A\u3059\u308B",
                        React.createElement(ArrowIcon, { classObject: styles.unsubscribeButtonArrow, color: Colors.Black, direction: ArrowDirection.right }))))))),
        showCancelUnsubscribeDialog && (React.createElement(CancelUnsubscribeConfirmDialog, { curPeriodEndDate: payment.curPeriodEndDate, onClickCancel: onCloseCancelUnsubscribeDialog, onClickSuccess: onSuccessCancelUnsubscribeDialog }))))));
};
var styles = StyleSheet.create({
    isDeletedContainer: (_a = {},
        _a[MediaBreakPointUp.MD] = {
            marginTop: 50,
        },
        _a),
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    planTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    planTitleNext: {
        fontSize: 16,
        color: Colors.Primary,
        fontWeight: 'bold',
        margin: '24px 0 20px',
        paddingTop: 24,
        borderTop: "1px solid ".concat(Colors.Gray),
    },
    planButtons: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        gap: 10,
    },
    queuedPlanUpdateDate: {
        marginTop: 24,
    },
    unsubscribeButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        gap: 3,
    },
    unsubscribeButtonArrow: {
        height: 9,
    },
    cancelUnsubscribeButton: {
        marginBottom: 20,
    },
    disabledButton: {
        color: Colors.GrayDarkest,
    },
    dormantAnnotation: {
        marginTop: 4,
        color: Colors.GrayDarkest,
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '0.5px',
    },
    coachingPlanDivider: (_b = {},
        // モバイルの時だけ画面の幅になる
        _b[MediaBreakPointDown.MD] = {
            position: 'relative',
            width: '100vw',
            left: 'calc((100% - 100vw)/ 2)',
        },
        _b.width = '100%',
        _b.marginBottom = 30,
        _b.border = "4px solid ".concat(Colors.CyanLightest),
        _b),
});
