import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { dateToYearMonthDayString } from '@lean-body/src/util';
import dayjs from 'dayjs';
export var ContractPlan = function (_a) {
    var nameClassObject = _a.nameClassObject, updateDateClassObject = _a.updateDateClassObject, name = _a.name, priceWithTax = _a.priceWithTax, updateDate = _a.updateDate, endDate = _a.endDate, endDateReason = _a.endDateReason, id = _a.id;
    var hideUpdateDate = function () {
        return updateDate && endDate && dayjs(updateDate).isSameOrAfter(endDate);
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.name, nameClassObject), id: id }, name),
        React.createElement("div", { className: css(styles.price) },
            "\u6599\u91D1: ",
            priceWithTax,
            "\u5186(\u7A0E\u8FBC)"),
        updateDate && !hideUpdateDate() && (React.createElement("div", { className: css(styles.updateDate, updateDateClassObject) },
            "\u66F4\u65B0\u65E5: ",
            dateToYearMonthDayString(updateDate))),
        endDate && (React.createElement("div", { className: css(styles.updateDate, updateDateClassObject) },
            dateToYearMonthDayString(endDate),
            "\u306B\u7D42\u4E86",
            endDateReason && "\uFF08".concat(endDateReason, "\uFF09")))));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    name: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: '0.5px',
    },
    price: {
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
    updateDate: {
        color: Colors.GrayDarkest,
        fontSize: 10,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: '0.5px',
        marginTop: 12,
    },
});
