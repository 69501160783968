import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '../atoms';
import { Dialog } from '../molecules';
import { dateToYearMonthDayString } from '@lean-body/src/util';
export var CancelUnsubscribeConfirmDialog = function (_a) {
    var curPeriodEndDate = _a.curPeriodEndDate, onClickSuccess = _a.onClickSuccess, onClickCancel = _a.onClickCancel, classObject = _a.classObject;
    return (React.createElement(Dialog, { onClickCancel: onClickCancel, classObject: [styles.dialog, classObject], content: React.createElement(React.Fragment, null,
            "\u9000\u4F1A\u3092\u53D6\u308A\u6D88\u3057\u3066\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F",
            React.createElement("br", null),
            "\u9000\u4F1A\u3092\u53D6\u308A\u6D88\u3059\u3068\u3001",
            dateToYearMonthDayString(curPeriodEndDate),
            "\u4EE5\u964D\u3082\u5F15\u304D\u7D9A\u304DLEAN BODY\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002",
            React.createElement("div", { className: css(styles.buttonContainer) },
                React.createElement(Button, { className: css(styles.button, styles.cancelButton), isRadius: true, buttonType: ButtonType.secondary, onClick: onClickCancel }, "\u53D6\u308A\u6D88\u3055\u306A\u3044"),
                React.createElement(Button, { className: css(styles.button, styles.successButton), isRadius: true, buttonType: ButtonType.primary, onClick: onClickSuccess }, "\u9000\u4F1A\u3092\u53D6\u308A\u6D88\u3059"))) }));
};
var styles = StyleSheet.create({
    dialog: {
        borderRadius: 14,
        maxWidth: 327,
        padding: '25px 24px 20px 24px',
        display: 'flex',
        gap: 20,
        flexDirection: 'column',
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '140%',
        letterSpacing: 0.56,
    },
    buttonContainer: {
        display: 'flex',
        gap: 10,
    },
    button: {
        padding: 0,
        height: 48,
        fontSize: 16,
        fontWeight: 700,
        lineHeight: '160%',
    },
    successButton: {
        width: 149,
    },
    cancelButton: {
        flex: 1,
    },
});
