import { Plan } from '.';
import * as dayjs from 'dayjs';
import { CoachingPayment } from './coaching_payment';
export var PaymentChannel = {
    MEGALOS: 'megalos',
    APPSTORE: 'appstore',
    STRIPE: 'stripe',
    EMPLOYEE_WELFARE: 'employee_welfare',
    COLLABORATION: 'collaboration',
    AMAZON_PAY: 'amazon_pay',
    None: 'none',
};
export var CollaborationServiceName = {
    WECLE: 'wecle',
    MEGALOS: 'megalos',
};
var Payment = /** @class */ (function () {
    function Payment(payment, config) {
        Object.defineProperty(this, "cardLastFour", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "subPlanId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "curPeriodStartTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "curPeriodEndTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "trialEndTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "curPeriodEndDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentChannel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tieUpPlanType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "queuedPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "corporationEmployee", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isExpired", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "collaborationUserPayment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "freeTrialEndAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dormantPeriodEnd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isMegalosUser", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isBillingGrace", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "coachingPayment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.cardLastFour = payment.cardLastFour;
        this.subPlanId = payment.subPlanId;
        this.curPeriodStartTime = payment.curPeriodStartTime;
        this.curPeriodEndTime = payment.curPeriodEndTime;
        this.trialEndTime = payment.trialEndTime; // 休眠はTrialEndを変更することで実現しているので、フロントでtrialEndTimeを利用する場合は注意が必要
        this.paymentChannel = payment.paymentChannel;
        this.tieUpPlanType = payment.tieUpPlanType;
        this.currentPlan = new Plan(payment.currentPlan, config);
        this.queuedPlan = payment.queuedPlan ? new Plan(payment.queuedPlan, config) : null;
        this.corporationEmployee = payment.corporationEmployee;
        this.collaborationUserPayment = payment.collaborationUserPayment;
        this.curPeriodEndDate = new Date(this.curPeriodEndTime * 1000);
        this.isExpired = payment.isExpired;
        this.freeTrialEndAt = payment.freeTrialEndAt || 0;
        this.dormantPeriodEnd = dayjs.unix(payment.dormantPeriodEnd);
        this.isMegalosUser = payment.isMegalosUser;
        this.isBillingGrace = payment.isBillingGrace;
        this.coachingPayment = payment.coachingPayment ? new CoachingPayment(payment.coachingPayment) : null;
    }
    Object.defineProperty(Payment.prototype, "curPeriodEndFormattedDate", {
        get: function () {
            var endDate = this.curPeriodEndDate;
            return "".concat(endDate.getFullYear(), "/").concat(endDate.getMonth() + 1, "/").concat(endDate.getDate());
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Payment.prototype, "coachingPaymentAvailable", {
        get: function () {
            return this.isStripePayment() || this.isAmazonPayUser();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Payment.prototype, "isAppstorePayment", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.paymentChannel === PaymentChannel.APPSTORE;
        }
    });
    Object.defineProperty(Payment.prototype, "isStripePayment", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.paymentChannel === PaymentChannel.STRIPE;
        }
    });
    // メガロス店舗で登録したユーザーかどうか
    Object.defineProperty(Payment.prototype, "isMegalosOptionUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.paymentChannel === PaymentChannel.MEGALOS;
        }
    });
    // 弊社のメガロスプランを登録したユーザーかどうか
    Object.defineProperty(Payment.prototype, "isMegalosPlanUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.tieUpPlanType === PaymentChannel.MEGALOS;
        }
    });
    // 福利厚生ユーザーかどうか
    Object.defineProperty(Payment.prototype, "isEmployeeWelfareUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.paymentChannel === PaymentChannel.EMPLOYEE_WELFARE;
        }
    });
    // 協業ユーザーかどうか
    Object.defineProperty(Payment.prototype, "isCollaborationUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.paymentChannel === PaymentChannel.COLLABORATION;
        }
    });
    // 引数で渡された支払いチャネルと現在の支払いチャネルが一致しているかどうか
    Object.defineProperty(Payment.prototype, "isPaymentChannelMatched", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var paymentChannel = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                paymentChannel[_i] = arguments[_i];
            }
            return paymentChannel.includes(this.paymentChannel);
        }
    });
    Object.defineProperty(Payment.prototype, "isAmazonPayUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.paymentChannel === PaymentChannel.AMAZON_PAY;
        }
    });
    // 法人ユーザーかどうか
    Object.defineProperty(Payment.prototype, "isCorporationUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return !!this.corporationEmployee;
        }
    });
    Object.defineProperty(Payment.prototype, "isFreeTrialUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.freeTrialEndAt > new Date().getTime() / 1000;
        }
    });
    Object.defineProperty(Payment.prototype, "isExpirationDateNearThanCoachingPayment", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (coaching) {
            if (!coaching)
                return false;
            return this.curPeriodEndTime < coaching.curPeriodEnd;
        }
    });
    return Payment;
}());
export { Payment };
