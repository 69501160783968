import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { PageLoading, RecommendDormant, RecommendDormantInputs } from '@lean-body/components/organisms';
import { Colors } from '../styles';
import { useQuery } from '@lean-body/src/util/custom_hooks';
import { useNavigate } from 'react-router';
import { routes } from '@lean-body/src/route';
import { SettingItemKeys } from '../organisms/setting_menu';
export var DormantPage = function (_a) {
    var vm = _a.vm;
    var _b = React.useState(null), payment = _b[0], setPayment = _b[1];
    var query = useQuery();
    var strictDormantMonth = query.get('dormantMonth');
    var navigate = useNavigate();
    var strictDormantMonthAvailable = function () {
        if (!strictDormantMonth)
            return true; // クエリがない場合は問題なし
        return RecommendDormantInputs.find(function (v) { return v.value === strictDormantMonth; });
    };
    var dormantAccount = function (month) {
        return vm.accountService.dormantAccount(month).then(function () {
            vm.tracker.trackBecomeDormant();
        });
    };
    React.useEffect(function () {
        if (strictDormantMonthAvailable()) {
            vm.accountService.fetchMe().then(function (v) {
                if (v.isDormantAvailable) {
                    setPayment(v.payment);
                }
                else {
                    vm.alertPresenter.showMessage('Stripeプランのみ利用可能です');
                    navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                }
            });
        }
        else {
            vm.alertPresenter.showMessage('不正な休眠期間です');
            navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
        }
    }, []);
    if (!payment) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "\u4F11\u7720\u30D7\u30E9\u30F3"),
        React.createElement(RecommendDormant, { classObject: styles.recommentDormant, payment: payment, dormantAccount: dormantAccount, strictDormantMonth: parseInt(strictDormantMonth) })));
};
var DormantPageVM = /** @class */ (function () {
    function DormantPageVM(accountService, alertPresenter, tracker) {
        Object.defineProperty(this, "accountService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "alertPresenter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tracker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.accountService = accountService;
        this.alertPresenter = alertPresenter;
        this.tracker = tracker;
    }
    return DormantPageVM;
}());
export { DormantPageVM };
var styles = StyleSheet.create({
    container: {
        minHeight: '100vh',
        backgroundColor: Colors.White,
        padding: '30px',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 24,
        textAlign: 'start',
    },
    recommentDormant: {
        maxWidth: 730,
        margin: '0 auto',
        textAlign: 'center',
    },
});
