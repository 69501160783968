var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import { PageLoading } from '@lean-body/components/organisms';
import * as msg from '@lean-body/src/messages';
import { useNavigate } from 'react-router-dom';
import { SettingItemKeys } from '../organisms/setting_menu';
import { dateToYearMonthDayString } from '@lean-body/src/util';
export var UnsubscribeConfirmPage = function (_a) {
    var accountService = _a.accountService, alertPresenter = _a.alertPresenter;
    var navigate = useNavigate();
    var _b = React.useState(), me = _b[0], setMe = _b[1];
    var _c = React.useState(false), isSubmitting = _c[0], setSubmitting = _c[1];
    var _d = React.useState(false), isSuccess = _d[0], setDeleteSuccess = _d[1];
    React.useEffect(function () {
        accountService.fetchMe().then(function (v) {
            setMe(v);
            if (v.unsubscribeScheduledAt) {
                navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
                alertPresenter.showMessage('退会予約ずみです');
            }
        });
    }, []);
    var onClickDeleteAccount = function () {
        setSubmitting(true);
        accountService
            .scheduleUnsubscribe()
            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setDeleteSuccess(true);
                alertPresenter.showMessage(msg.SCHEDULE_UNSUBSCRIBE_SUCCEEDED, 'cancel_membership');
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            alertPresenter.showError(err, msg.SCHEDULE_UNSUBSCRIBE_FAILED, 'cancel_membership');
        })
            .finally(function () {
            setSubmitting(false);
        });
    };
    var onClickBackToPlanSettings = function () {
        navigate("".concat(routes.SETTING.compile(), "?key=").concat(SettingItemKeys.plan));
    };
    if (!me)
        return React.createElement(PageLoading, null);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.title) }, "\u9000\u4F1A\u624B\u7D9A\u304D"),
        React.createElement("div", { className: css(styles.descBold) }, isSuccess ? '退会処理が完了しました' : '退会してよろしいですか？'),
        React.createElement("div", { className: css(styles.desc, isSuccess && styles.descSuccess) }, isSuccess ? (React.createElement(React.Fragment, null,
            dateToYearMonthDayString(me.payment.curPeriodEndDate),
            "\u307E\u3067\u306F\u5F15\u304D\u7D9A\u304DLEAN BODY\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\u3002",
            React.createElement("br", null),
            React.createElement("br", null),
            React.createElement("span", { className: css(styles.important) },
                dateToYearMonthDayString(me.payment.curPeriodEndDate),
                "\u307E\u3067\u306F\u9000\u4F1A\u3092\u53D6\u308A\u6D88\u3057\u3001\u7D99\u7D9A\u5229\u7528\u3059\u308B\u3053\u3068\u3082\u53EF\u80FD\u3067\u3059\u3002"))) : ('問題がなければ「退会する」を押してください。')),
        !isSuccess && (React.createElement(Button, { className: css(styles.button), disabled: isSubmitting, loading: isSubmitting, onClick: onClickDeleteAccount, dataTest: "deleteAccount", buttonType: ButtonType.primary }, "\u9000\u4F1A\u3059\u308B")),
        React.createElement(Button, { className: css(styles.button), buttonType: isSuccess ? ButtonType.primary : ButtonType.whiteWithBlack, disabled: isSubmitting, loading: isSubmitting, onClick: onClickBackToPlanSettings }, "\u30D7\u30E9\u30F3\u3092\u78BA\u8A8D\u3059\u308B")));
};
var styles = StyleSheet.create({
    container: {
        minHeight: '100vh',
        backgroundColor: Colors.White,
        padding: '30px',
        textAlign: 'center',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        padding: '40px 0 24px',
        textAlign: 'start',
        marginBottom: 30,
    },
    descBold: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    desc: {
        fontSize: 14,
        marginBottom: 20,
    },
    descSuccess: (_a = {
            textAlign: 'start'
        },
        _a[MediaBreakPointUp.MD] = {
            textAlign: 'center',
        },
        _a),
    important: {
        color: Colors.Accent,
    },
    button: (_b = {
            width: '100%',
            marginBottom: 10,
            fontSize: 14,
            display: 'block'
        },
        _b[MediaBreakPointUp.MD] = {
            maxWidth: 240,
            margin: '0 auto 10px',
        },
        _b),
});
