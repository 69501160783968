import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { PaymentChannel } from '@lean-body/src/domain';
import { Button, ButtonType } from '@lean-body/components/atoms/button';
import { Colors } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import * as msg from '@lean-body/src/messages';
import { useNavigate } from 'react-router';
import { SettingStyles } from '../pages/main_setting';
import { StripeAmazonPayPlanSection } from '../molecules/stripe_amazon_pay_plan_section';
export var PlanSubsection = function (_a) {
    var _b;
    var classObject = _a.classObject, accountService = _a.accountService, apiClient = _a.apiClient, tracker = _a.tracker, alertPresenter = _a.alertPresenter, me = _a.me, setMe = _a.setMe;
    var navigate = useNavigate();
    var collaboration = (_b = me.payment.collaborationUserPayment) === null || _b === void 0 ? void 0 : _b.collaborationService;
    var _c = React.useState(me.payment), payment = _c[0], setPayment = _c[1];
    var _d = React.useState(me.payment.currentPlan), currentPlan = _d[0], setCurrentPlan = _d[1];
    var _e = React.useState(false), isQueuedPlanForPricingRevision = _e[0], setIsQueuedPlanForPricingRevision = _e[1];
    var _f = React.useState(false), isSubmitting = _f[0], setIsSubmitting = _f[1];
    React.useEffect(function () {
        setPayment(me.payment);
        setCurrentPlan(me.payment.currentPlan);
        if (me.payment.queuedPlan != null) {
            apiClient.getIsQueuedPlanForPricingRevision().then(function (result) {
                setIsQueuedPlanForPricingRevision(result);
            });
        }
    }, [me]);
    var onRemoveQueuedPlan = function () {
        apiClient
            .removeQueuedPlan()
            .then(function () {
            tracker.trackClickRemovePlan(me);
            accountService.fetchMe({ nocache: true }).then(function (me) {
                setMe(me);
                alertPresenter.showMessage(msg.PLAN_RESET_SUCCEEDED);
            });
        })
            .catch(function (res) {
            alertPresenter.showMessage(msg.PLAN_RESET_FAILED);
            throw res;
        });
    };
    var onClickReactivateDormant = function () {
        setIsSubmitting(true);
        accountService
            .reactivateAccount()
            .then(function () {
            alertPresenter.showMessage(msg.ACCOUNT_ACTIVATED);
            navigate(routes.TOP.compile());
        })
            .catch(function (res) {
            alertPresenter.showError(res, msg.ACTIVATE_FAILED, 'activate_failed');
            throw res;
        })
            .finally(function () {
            setIsSubmitting(false);
        });
    };
    var onCancelUnsubscribe = function () {
        apiClient
            .cancelUnsubscribe()
            .then(function () {
            accountService.fetchMe({ nocache: true }).then(function (me) {
                setMe(me);
                alertPresenter.showMessage(msg.CANCEL_UNSUBSCRIBE_SUCCEEDED);
            });
        })
            .catch(function (res) {
            alertPresenter.showMessage(msg.CANCEL_UNSUBSCRIBE_FAILED);
            throw res;
        });
    };
    return (React.createElement("div", { className: css(styles.container, classObject) }, me.isDormant ? (React.createElement("div", null,
        React.createElement("div", { className: css(styles.planTitle) }, "\u73FE\u5728\u4F11\u7720\u4E2D\u3067\u3059"),
        "\u3042\u306A\u305F\u306E\u30A2\u30AB\u30A6\u30F3\u30C8\u306F\u73FE\u5728\u4F11\u7720\u30A2\u30AB\u30A6\u30F3\u30C8\u306B\u306A\u3063\u3066\u3044\u307E\u3059\u3002\u9014\u4E2D\u3067\u4E00\u822C\u4F1A\u54E1\u306B\u5FA9\u65E7\u3059\u308B\u4E8B\u3082\u3067\u304D\u307E\u3059\u3002",
        React.createElement("div", { className: css(styles.dormantEndDate) },
            "\u4F11\u7720\u671F\u9593: ",
            payment.dormantPeriodEnd.format('YYYY年M月D日'),
            "\u307E\u3067"),
        React.createElement(Button, { onClick: onClickReactivateDormant, loading: isSubmitting, disabled: isSubmitting, buttonType: ButtonType.primary, className: css(SettingStyles.button) }, "\u4E00\u822C\u4F1A\u54E1\u306B\u5FA9\u65E7\u3059\u308B"))) : payment.isEmployeeWelfareUser() ? (
    ////////////////////////////////
    // 福利厚生プラン
    ///////////////////////////////
    React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
        React.createElement("div", { className: css(styles.planName) }, currentPlan.name),
        React.createElement("div", { className: css(styles.price) },
            "\u6599\u91D1: ",
            currentPlan.amount.toLocaleString(),
            "\u5186(\u7A0E\u8FBC)"))) : payment.isPaymentChannelMatched(PaymentChannel.MEGALOS, PaymentChannel.COLLABORATION) ? (
    ////////////////////////////////
    // Megalosプラン or 協業プラン
    ///////////////////////////////
    React.createElement(React.Fragment, null,
        payment.isMegalosOptionUser() && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
            React.createElement("div", { className: css(styles.planName) }, currentPlan.name),
            React.createElement("div", { className: css(styles.price) },
                "\u6599\u91D1: ",
                currentPlan.amount.toLocaleString(),
                "\u5186(\u7A0E\u8FBC)"))),
        React.createElement("div", null,
            "\u203B\u6599\u91D1\u306E\u8ACB\u6C42\u306B\u95A2\u3059\u308B\u554F\u3044\u5408\u308F\u305B\u306F\u4E0B\u8A18\u306B\u3066\u304A\u9858\u3044\u3057\u307E\u3059",
            React.createElement("a", { href: collaboration.inquiryLink, target: "_blank", "data-test": "contactMegalos", className: css(styles.inquiryLink) },
                "\u30FB\u304A\u554F\u3044\u5408\u308F\u305B | ",
                collaboration.inquiryLinkText)))) : payment.isAppstorePayment() ? (
    ///////////////////////
    // AppStoreプラン
    //////////////////////
    React.createElement("div", null,
        React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
        React.createElement("div", null, "iPhone\u30A2\u30D7\u30EA\u304B\u3089\u8AB2\u91D1\u3057\u305F\u65B9\u306FiPhone\u306E\u8A2D\u5B9A\u304B\u3089\u30D7\u30E9\u30F3\u60C5\u5831\u3092\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044"),
        React.createElement("a", { className: css(styles.inquiryLink), href: "https://wondernuts.zendesk.com/hc/ja/articles/360002354634-LEAN-BODY%E3%82%92%E9%80%80%E4%BC%9A%E3%81%99%E3%82%8B%E3%81%AB%E3%81%AF%E3%81%A9%E3%81%86%E3%81%99%E3%82%8C%E3%81%B0%E8%89%AF%E3%81%84%E3%81%A7%E3%81%99%E3%81%8B-", target: "_blank" }, "\u9000\u4F1A\u65B9\u6CD5\u306B\u3064\u3044\u3066\u306F\u3053\u3061\u3089"))) : payment.isPaymentChannelMatched(PaymentChannel.None) ? (
    ///////////////////////
    // 課金不要プラン
    //////////////////////
    React.createElement("div", null,
        React.createElement("div", { className: css(styles.planTitle) }, "\u3054\u5229\u7528\u4E2D\u306E\u30D7\u30E9\u30F3"),
        React.createElement("div", { className: css(styles.planName) }, currentPlan.name),
        React.createElement("div", { className: css(styles.planName) }, "\u203B\u5229\u7528\u671F\u9593\u4EE5\u964D\u7D99\u7D9A\u3055\u308C\u305F\u3044\u5834\u5408\u306F\u3001\u671F\u9593\u7D42\u4E86\u5F8C\u306B\u518D\u5EA6\u767B\u9332\u753B\u9762\u304B\u3089\u304A\u652F\u6255\u3044\u60C5\u5831\u306E\u5165\u529B\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002"))) : (
    /////////////////////////////////////
    // Stripeプラン or AmazonPayプラン
    /////////////////////////////////////
    React.createElement(StripeAmazonPayPlanSection, { me: me, isQueuedPlanForPricingRevision: isQueuedPlanForPricingRevision, onCancelUnsubscribe: onCancelUnsubscribe, onRemoveQueuedPlan: onRemoveQueuedPlan }))));
};
var styles = StyleSheet.create({
    container: {
        textAlign: 'center',
        fontSize: 14,
    },
    planTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    planName: {
        fontSize: 10,
    },
    price: {
        fontWeight: 'bold',
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
    dormantEndDate: {
        fontWeight: 'bold',
        margin: '40px 0',
    },
    inquiryLink: {
        display: 'block',
        textDecoration: 'underline',
        color: Colors.Black,
        marginTop: 40,
    },
});
