var _a, _b, _c, _d;
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '@lean-body/src/route';
import { InitialGuideCard } from '../molecules';
import { InitialGuideQA } from '../molecules/initial_guide_qa';
import { InitialGuideDetailTypes, INITIAL_GUIDE_TYPE_QUERY_PARAMS } from './main_initial_guide_detail';
import { storeLink } from '../organisms';
import { isiOSWeb, isNativeWeb } from '@lean-body/src/util';
var InitialGuideList = [
    {
        tag: '＼人気／',
        img: '/assets/images/initial_guide/live_card_icon.png',
        title: 'LIVE',
        description: 'リアルタイムにレッスンが受けられる！',
        detail: InitialGuideDetailTypes.live,
    },
    {
        img: '/assets/images/initial_guide/series_card_icon.png',
        title: 'シリーズ',
        description: '同じシリーズのレッスンをコンプリートしましょう！',
        detail: InitialGuideDetailTypes.series,
    },
    {
        img: '/assets/images/initial_guide/search_card_icon.png',
        title: '見つける',
        description: '絞り込み検索でお気に入りのレッスンが見つかる！',
        detail: InitialGuideDetailTypes.search,
    },
    {
        img: '/assets/images/initial_guide/challenge_playlist_card_icon.png',
        title: 'チャレンジプレイリスト',
        description: 'あなたに合ったテーマと期間を決めて修了証をGET！',
        detail: InitialGuideDetailTypes.challengePlaylist,
    },
    {
        img: '/assets/images/initial_guide/playlist_card_icon.png',
        title: 'プレイリスト',
        description: 'あなたのペースで楽しめるレッスンがきっと見つかる！',
        detail: InitialGuideDetailTypes.playlist,
    },
    {
        img: '/assets/images/initial_guide/my_lesson_card_icon.png',
        title: 'MYレッスン',
        description: 'お気に入りのレッスンをまとめてチェック！',
        detail: InitialGuideDetailTypes.myLesson,
    },
];
var link = {
    watchTV: 'https://wondernuts.zendesk.com/hc/ja/articles/360011873393-LEAN-BODY%E3%82%92%E3%83%86%E3%83%AC%E3%83%93%E3%81%A7%E8%A6%96%E8%81%B4%E3%81%99%E3%82%8B%E3%81%93%E3%81%A8%E3%81%AF%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-',
    helpCenter: 'https://wondernuts.zendesk.com/hc/ja',
};
export var MainInitialGuide = function () {
    var navigate = useNavigate();
    var onClick = function (detailParam) {
        navigate("".concat(routes.INITIAL_GUIDE_DETAIL.compile(), "?").concat(INITIAL_GUIDE_TYPE_QUERY_PARAMS, "=").concat(detailParam));
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("img", { className: css(styles.topImg), src: "/assets/images/initial_guide/initial_guide_top.png" }),
        React.createElement("div", { className: css(styles.descriptionBox) },
            React.createElement("div", { className: css(styles.descriptionText) },
                "LEAN BODY\u306B\u306F1,000\u4EE5\u4E0A\u306E\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9\u52D5\u753B\u304C\u3042\u308A\u307E\u3059\u3002",
                React.createElement("br", null),
                "\u3069\u3093\u306A\u30EC\u30C3\u30B9\u30F3\u3092\u53D7\u3051\u305F\u3089\u3044\u3044\u304B\u8FF7\u3044\u307E\u3059\u3088\u306D\u3002\u305D\u3093\u306A\u6642\u306B\u304A\u3059\u3059\u3081\u306E\u30EC\u30C3\u30B9\u30F3\u306E\u53D7\u3051\u65B9\u3092\u3054\u7D39\u4ECB\u3057\u307E\u3059\uFF01\u3042\u306A\u305F\u306B\u5408\u3063\u305F\u4F7F\u3044\u65B9\u3092\u63A2\u3057\u3066\u307F\u307E\u3057\u3087\u3046\u3002")),
        React.createElement("div", { className: css(styles.bgLightGraySection) },
            React.createElement("div", { className: css(styles.section) },
                React.createElement("div", { className: css(styles.sectionTitle) }, "\u307E\u305A\u306F\u3053\u3053\u304B\u3089\uFF01"),
                React.createElement("div", { className: css(styles.sectionTitleBorder) }),
                React.createElement(InitialGuideCard, { imgSrc: "/assets/images/initial_guide/course_card_icon.png", title: "\u30B3\u30FC\u30B9", description: "\u304A\u3059\u3059\u3081\u306E\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u3092HOME\u306B\u63D0\u6848\uFF01", tag: "\uFF3C\u304A\u3059\u3059\u3081\uFF0F", onClick: function () { return onClick(InitialGuideDetailTypes.course); } })),
            React.createElement("div", { className: css(styles.section, styles.noMarginBottom) },
                React.createElement("div", { className: css(styles.sectionTitle) }, "\u3082\u3063\u3068\u30C1\u30E3\u30EC\u30F3\u30B8\u3057\u305F\u3044\u65B9\u306F\u3053\u3061\u3089"),
                React.createElement("div", { className: css(styles.sectionTitleBorder) }),
                InitialGuideList.map(function (v, i) { return (React.createElement("div", { key: i },
                    React.createElement(InitialGuideCard, { imgSrc: v.img, title: v.title, description: v.description, tag: v.tag, onClick: function () { return onClick(v.detail); } }))); }))),
        React.createElement("div", { className: css(styles.qaSection) },
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u3088\u304F\u3042\u308B\u8CEA\u554F"),
            React.createElement("div", { className: css(styles.sectionTitleBorder) }),
            React.createElement(InitialGuideQA, { question: "\u30C6\u30EC\u30D3\u306B\u6620\u3059\u3053\u3068\u306F\u3067\u304D\u307E\u3059\u304B\uFF1F" },
                "LEAN BODY\u3092\u3054\u5BB6\u5EAD\u306E\u30C6\u30EC\u30D3\u3067\u3054\u8996\u8074\u3044\u305F\u3060\u304F\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002\u8A73\u3057\u3044\u65B9\u6CD5\u306F",
                React.createElement(Link, { to: link.watchTV, className: css(styles.link), target: "_blank" }, "\u3053\u3061\u3089"),
                "\u3092\u3054\u89A7\u304F\u3060\u3055\u3044\u3002"),
            isNativeWeb() && (React.createElement(InitialGuideQA, { question: "LEAN BODY\u306B\u30A2\u30D7\u30EA\u306F\u3042\u308A\u307E\u3059\u304B\uFF1F" },
                "LEAN BODY\u306B\u306Fweb\u7248\u3068\u30A2\u30D7\u30EA\u7248\u304C\u3042\u308A\u307E\u3059\u3002\u30B9\u30DE\u30FC\u30C8\u30D5\u30A9\u30F3\u3092\u3054\u5229\u7528\u306E\u65B9\u306F",
                React.createElement(Link, { to: storeLink(), className: css(styles.link), target: "_blank" }, isiOSWeb() ? 'App Store' : 'Google Play'),
                "\u304B\u3089\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u304C\u53EF\u80FD\u3067\u3059\u3002\u305C\u3072\u3054\u5229\u7528\u304F\u3060\u3055\u3044\u3002"))),
        React.createElement("div", { className: css(styles.helpCenterText) },
            "\u4E0A\u8A18\u306B\u8A18\u8F09\u3055\u308C\u3066\u3044\u306A\u3044\u5185\u5BB9\u3067\u304A\u56F0\u308A\u306E\u65B9\u3084\u3001\u3055\u3089\u306B\u77E5\u308A\u305F\u3044\u65B9\u306F",
            React.createElement(Link, { to: link.helpCenter, className: css(styles.link), target: "_blank" }, "\u30D8\u30EB\u30D7\u30BB\u30F3\u30BF\u30FC"),
            "\u3092\u3054\u89A7\u304F\u3060\u3055\u3044\u3002")));
};
var styles = StyleSheet.create({
    container: (_a = {
            marginBottom: 20
        },
        _a[MediaBreakPointUp.MD] = {
            marginBottom: 40,
        },
        _a[MediaBreakPointUp.XL] = {
            width: 696,
            margin: '0 auto 40px',
        },
        _a),
    bgLightGraySection: {
        backgroundColor: Colors.BackgroundLightGray,
        paddingTop: 40,
        paddingBottom: 20,
    },
    topImg: {
        width: '100%',
    },
    section: (_b = {
            marginBottom: 50,
            paddingLeft: 24,
            paddingRight: 24
        },
        _b[MediaBreakPointUp.MD] = {
            paddingLeft: 40,
            paddingRight: 40,
        },
        _b),
    noMarginBottom: {
        marginBottom: 0,
    },
    qaSection: (_c = {
            marginTop: 40,
            paddingLeft: 24,
            paddingRight: 24
        },
        _c[MediaBreakPointUp.MD] = {
            paddingLeft: 40,
            paddingRight: 40,
        },
        _c),
    descriptionBox: {
        padding: 24,
    },
    descriptionText: {
        color: Colors.Black,
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.56px',
    },
    sectionTitle: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '1.44px',
        height: 25,
    },
    sectionTitleBorder: {
        border: "3px solid ".concat(Colors.PrimaryDark),
        borderRadius: 3,
        width: 60,
        margin: '12px auto 20px',
    },
    link: {
        color: Colors.PrimaryDark,
        fontSize: 14,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    helpCenterText: (_d = {
            fontSize: 14,
            fontWeight: 400,
            padding: '30px 24px',
            lineHeight: '160%',
            letterSpacing: '0.56px'
        },
        _d[MediaBreakPointUp.MD] = {
            padding: '30px 40px',
        },
        _d),
});
