var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import bind from 'bind-decorator';
import * as dayjs from 'dayjs';
import { camelize } from 'inflected';
import { isPlainObject } from 'lodash-es';
import { isAndroidApp, underscoreKeys } from '@lean-body/src/util';
import { getFormattedToFormat } from '@lean-body/src/util';
import { Announcement, Announcements, Badge, Campaign, Lesson, LessonSession, LiveLesson, MatchingAssessment, Plan, Playlist, Program, SearchResult, Trainer, UserChallenge, UserProfile, WatchedLessonRanking, UserLessonSessionGroupByDaily, UserWeight, Activity, Banner, Dialog, Footer, Maintenance, FriendInvitationCampaign, Style, Tag, UserSeasonAggregate, BasePlan, Payment, AmazonPayPlan, Course, CourseVolume, CourseSchedule, CourseDay, SnsShareContent, Reward, StepActivity, DailyWorkoutTask, DailyStepsTask, DailyTasks, CoachingPlan, CoachingAmazonPayPlan, GoalMatchingAssessmentResponse, WeightGoalApproach, GoalMatchingAssessmentAnswerResponse, MealMenu, UserMeal, LessonSection, } from '../domain';
import { Merchandise } from '../domain/merchandise';
import { APIServerError, BadRequestError, ForbiddenError, NotFoundError, UnauthorizedError, UnderMaintenance, } from '../errors';
import { APISequences, } from '../interfaces';
import { CustomWorkout } from '../domain/custom_workout';
import { Nutrient } from '../domain/nutrient';
import { MealScore } from '../domain/meal_score';
import { WorkoutCalories } from '../domain/workout_calories';
import { HomeCalendar } from '../domain/home_calendar';
/*=============================================
= API Client =
=============================================*/
/**
 * APIクライアント
 * @class
 */
var APIClient = /** @class */ (function () {
    function APIClient(config, localStorage, unauthorizedCallback, maintenanceCallback) {
        var _this = this;
        /**
         * 認証成功時にgetできるauthトークン
         */
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "axios", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unauthorizedCallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maintenanceCallback", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "requestQueues", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        this.localStorage = localStorage;
        this.unauthorizedCallback = unauthorizedCallback;
        this.maintenanceCallback = maintenanceCallback;
        this.config = config;
        this.axios = axios.create({
            baseURL: config.apiBaseURL,
            timeout: 20000, // 20000ms = 20sec
            withCredentials: true,
        });
        this.axios.interceptors.request.use(function (req) {
            req.data = underscoreKeys(req.data);
            var headers = { 'X-Platform': isAndroidApp() ? 'android' : 'web' };
            req.headers = __assign(__assign({}, req.headers), headers);
            return req;
        });
        this.axios.interceptors.response.use(function (res) {
            res.data = _this.camelizeKeys(res.data);
            return res;
        }, function (error) {
            _this.onError(error);
        });
    }
    Object.defineProperty(APIClient.prototype, "onError", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (error) {
            if ('config' in error) {
                var e = error;
                if (!e.response) {
                    throw e;
                }
                var data = this.camelizeKeys(e.response.data);
                console.log(data); // sentryでエラーの文言を表示できるように出力する
                switch (e.response.status) {
                    case 401: // jwtトークン期限切れ
                        this.unauthorizedCallback();
                        throw new UnauthorizedError(e.message, data.message, e.response.status);
                    case 403:
                        throw new ForbiddenError(e.message, data.message, e.response.status, data.errorCode);
                    case 404:
                        throw new NotFoundError(e.message, data.message, e.response.status);
                    case 400:
                        throw new BadRequestError(e.message, data.message, e.response.status);
                    // サーバメンテナンス状態
                    case 503: {
                        this.maintenanceCallback();
                        var maintenance = new Maintenance(data);
                        throw new UnderMaintenance(e.message, e.response.status, maintenance);
                    }
                    default:
                        if (data.message) {
                            throw new APIServerError(e.message, data.message);
                        }
                        else {
                            throw error;
                        }
                }
            }
            throw error;
        }
    });
    Object.defineProperty(APIClient.prototype, "camelizeKeys", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (object) {
            var _this = this;
            if (Array.isArray(object)) {
                return object.map(function (v) { return _this.camelizeKeys(v); });
            }
            else {
                if (isPlainObject(object)) {
                    var camelized_1 = {};
                    Object.keys(object).forEach(function (key) {
                        camelized_1[camelize(key, false)] = _this.camelizeKeys(object[key]);
                    });
                    return camelized_1;
                }
                else {
                    return object;
                }
            }
        }
    });
    // 別々クラスで呼ばれるAPIリクエストを先着順で実行するコントロール処理
    Object.defineProperty(APIClient.prototype, "appendToSequence", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (name) {
            return __awaiter(this, void 0, void 0, function () {
                var queue, resolve, promise, next, idx;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.requestQueues.has(name)) {
                                queue = this.requestQueues.get(name);
                            }
                            else {
                                queue = [];
                                this.requestQueues.set(name, queue);
                            }
                            promise = new Promise(function (r) {
                                resolve = r;
                            });
                            next = function () {
                                queue.shift();
                                resolve(null);
                            };
                            queue.push(promise);
                            idx = queue.indexOf(promise);
                            return [4 /*yield*/, queue[idx - 1]];
                        case 1:
                            _a.sent();
                            return [2 /*return*/, next];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "signUp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/users', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "signIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/auth/token', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "signOut", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete('/me/auth/token')];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "ping", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/ping')];
                        case 1:
                            res = _a.sent();
                            return [4 /*yield*/, res.data.response];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * FBサインイン・登録
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "fbSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/fb_signin', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * LINEサインイン・登録
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "lineSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/line_signin', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * Appleサインイン nonce取得
     */
    Object.defineProperty(APIClient.prototype, "nonce", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/nonce')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * MeRepositoryだけで使用するmethod
     * meの取得はAccountService#fetchMeを利用する
     */
    Object.defineProperty(APIClient.prototype, "fetchMe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * メアド・支払い情報などのプロフィール情報を取得
     */
    Object.defineProperty(APIClient.prototype, "authorization", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/authorization')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * 署名付きCookie取得
     */
    Object.defineProperty(APIClient.prototype, "fetchSignedCookies", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/signed_cookies').catch(function (err) {
                                if (err.isUnauthorizedError)
                                    return; // 有効期限切れの場合、sentryへの送信しない
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * 自分の招待コードを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchInvitationCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/invitation_code')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * お友だち紹介で利用できるキャンペーンコードを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchInvitationCampaignCode", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/invitation_campaign_code')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * クレカ登録
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "registerPayment", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/payment', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Payment(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * @deprecated
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "registerOnce", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/registration_once', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * コーチングプラン取得
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "fetchCoachingPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/coaching_plan')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new CoachingPlan(res.data.response)];
                    }
                });
            });
        }
    });
    /**
     * AmazonPayコーチングプラン取得
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "fetchCoachingAmazonPayPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/coaching_amazon_pay_plan')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new CoachingAmazonPayPlan(res.data.response)];
                    }
                });
            });
        }
    });
    /**
     * コーチングプラン登録
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "registerCoachingPayment", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/coaching_payment', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * コーチングプラン停止
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "deleteCoachingPayment", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete('/me/coaching_payment')];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * メアド変更
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "changeEmail", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/email', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * パスワード変更
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "changePassword", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/password', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * 支払い情報変更
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "changePaymentSource", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/payment', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * Program一覧取得
     * @param page
     * @param sortBy
     */
    Object.defineProperty(APIClient.prototype, "fetchProgramList", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page, sortBy) {
            return __awaiter(this, void 0, void 0, function () {
                var res, programData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/programs?page=".concat(page, "&sort_by=").concat(sortBy))];
                        case 1:
                            res = _a.sent();
                            programData = res.data || [];
                            return [2 /*return*/, programData.map(function (v) { return new Program(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * プログラム単体取得
     * @param slug
     */
    Object.defineProperty(APIClient.prototype, "fetchProgramWithSlug", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (slug) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/programs/".concat(slug))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Program(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * レッスン単体を取得
     * @param slug
     */
    Object.defineProperty(APIClient.prototype, "fetchLessonWithSlug", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (slug, courseLessonID) {
            return __awaiter(this, void 0, void 0, function () {
                var queryParams, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            queryParams = courseLessonID ? "?course_days_lesson_id=".concat(courseLessonID) : '';
                            return [4 /*yield*/, this.axios.get("/lessons/".concat(slug).concat(queryParams))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Lesson(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * スタイル(レッスン様式)を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchStyles", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/styles")];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.map(function (v) { return new Style(v); })];
                    }
                });
            });
        }
    });
    /**
     * レッスンの部位を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchPartTags", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/part_tags")];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.map(function (v) { return new Tag(v); })];
                    }
                });
            });
        }
    });
    /**
     * レッスンの時間を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchDurationsForSearch", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/durations")];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * 動画視聴開始時に叩かれる。動画視聴のセッションを開始
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "startLessonSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post("/me/lesson_sessions", params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * 動画視聴中に一定間隔で叩く。視聴アクティビティ情報を同期する。
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "syncLessonSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            this.axios.patch('/me/lesson_sessions/sync', params);
        }
    });
    /**
     * 動画視聴終了時に叩く。startLessonSessionと対になる
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "finishLessonSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/lesson_sessions/finish', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * ライブレッスン視聴記録作成(Start)
     */
    Object.defineProperty(APIClient.prototype, "startLiveLessonWatchingRecord", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var res, session;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post("/me/live_lessons/".concat(id, "/watching_record"))];
                        case 1:
                            res = _a.sent();
                            session = res.data.response.session;
                            return [2 /*return*/, new LessonSession(session, this.config)];
                    }
                });
            });
        }
    });
    /**
     * ライブレッスン視聴記録更新(Complete)
     */
    Object.defineProperty(APIClient.prototype, "finishLiveLessonWatchingRecord", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id, lessonWatchingTime, deduplicatedLessonWatchingTime, userLessonSessionId) {
            return __awaiter(this, void 0, void 0, function () {
                var res, _a, session, userSeasonAggregate;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.axios.patch("/me/live_lessons/".concat(id, "/watching_record"), {
                                lessonWatchingTime: lessonWatchingTime,
                                deduplicatedLessonWatchingTime: deduplicatedLessonWatchingTime,
                                userLessonSessionId: userLessonSessionId,
                            })];
                        case 1:
                            res = _b.sent();
                            _a = res.data.response, session = _a.session, userSeasonAggregate = _a.userSeasonAggregate;
                            this.localStorage.saveUserSeasonAggregateRank(userSeasonAggregate);
                            return [2 /*return*/, new LessonSession(session, this.config)];
                    }
                });
            });
        }
    });
    /**
     * ライブレッスン視聴記録更新(Sync)
     */
    Object.defineProperty(APIClient.prototype, "syncLiveLessonWatchingRecord", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id, lessonWatchingTime, deduplicatedLessonWatchingTime, userLessonSessionId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch("/me/live_lessons/".concat(id, "/sync_watching_record"), {
                                lessonWatchingTime: lessonWatchingTime,
                                deduplicatedLessonWatchingTime: deduplicatedLessonWatchingTime,
                                userLessonSessionId: userLessonSessionId,
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * レッスン動画視聴履歴取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLessonHistory", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, arguments, void 0, function (page) {
                var res;
                var _this = this;
                if (page === void 0) { page = 0; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/lessons?filter=watched&page=".concat(page))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (d) { return new Lesson(d, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * 視聴履歴データを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLessonSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, arguments, void 0, function (page) {
                var res;
                var _this = this;
                if (page === void 0) { page = 0; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/lesson_history?page=".concat(page))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (session) { return new LessonSession(session, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * レッスンセクションデータを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLessonSection", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/lesson_sections')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (lessonSection) { return new LessonSection(lessonSection, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * 視聴アクティビティの集計を行なった後、メールなどの普遍的な情報以外のプロフィールを取得。(視聴回数など)
     */
    Object.defineProperty(APIClient.prototype, "patchProfile", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/aggregated_profile')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new UserProfile(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * userProfile を取得.
     */
    Object.defineProperty(APIClient.prototype, "fetchMypageProfile", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/mypage_profile')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new UserProfile(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * userProfile を更新.
     */
    Object.defineProperty(APIClient.prototype, "patchMypageProfile", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/mypage_profile?check_required=1', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new UserProfile(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * 日毎のアクティビティのサマリを取得.
     * @param year
     * @param month
     * @param date
     */
    Object.defineProperty(APIClient.prototype, "fetchDailyActivity", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (year, month, date) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/summary/daily-activity?year=".concat(year, "&month=").concat(month, "&date=").concat(date))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Activity(res.data)];
                    }
                });
            });
        }
    });
    /**
     * 週のアクティビティのサマリを取得.
     */
    Object.defineProperty(APIClient.prototype, "fetchWeeklyActivity", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (startDate, page) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/summary/weekly-activity?start_date=".concat(getFormattedToFormat(startDate), "&page=").concat(page))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Activity(res.data)];
                    }
                });
            });
        }
    });
    /**
     * 月毎のアクティビティのサマリを取得.
     * @param year
     * @param month
     */
    Object.defineProperty(APIClient.prototype, "fetchMonthlyActivity", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (startDate, page) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/summary/monthly-activity?start_date=".concat(getFormattedToFormat(startDate), "&page=").concat(page))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Activity(res.data)];
                    }
                });
            });
        }
    });
    /**
     * 3ヶ月毎のアクティビティのサマリを取得.
     * @param page
     */
    Object.defineProperty(APIClient.prototype, "fetchThreeMonthActivity", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (startDate, page) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/summary/quarterly-activity?start_date=".concat(getFormattedToFormat(startDate), "&page=").concat(page))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Activity(res.data)];
                    }
                });
            });
        }
    });
    /**
     * アクティビティページで使う、レッスンを実施した日一覧を取得
     * @param year
     * @param month
     */
    Object.defineProperty(APIClient.prototype, "fetchActivityCalendar", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (year, month) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/activity_calendar?year=".concat(year, "&month=").concat(month))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * ホームのカレンダー日付を取得
     * @param dateString
     */
    Object.defineProperty(APIClient.prototype, "fetchHomeCalendar", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (dateString) {
            return __awaiter(this, void 0, void 0, function () {
                var res, homeCalendarData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/home_calendar?target_date=".concat(dateString))];
                        case 1:
                            res = _a.sent();
                            homeCalendarData = res.data.response || [];
                            return [2 /*return*/, homeCalendarData.map(function (v) { return new HomeCalendar(v); })];
                    }
                });
            });
        }
    });
    /**
     * 視聴回数を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLessonViewCounts", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/lesson_view_counts')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data || []];
                    }
                });
            });
        }
    });
    /**
     * プログラムfav一覧取得
     */
    Object.defineProperty(APIClient.prototype, "fetchFavoritePrograms", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, favoriteProgramData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/favorite_programs')];
                        case 1:
                            res = _a.sent();
                            favoriteProgramData = res.data || [];
                            return [2 /*return*/, favoriteProgramData.map(function (v) {
                                    if (v.program.isPlaylist) {
                                        return new Playlist(v.program, _this.config);
                                    }
                                    return new Program(v.program, _this.config);
                                })];
                    }
                });
            });
        }
    });
    /**
     * レッスンのfav(ページングしている)一覧取得
     * @param page
     */
    Object.defineProperty(APIClient.prototype, "fetchFavoriteLessons", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page) {
            return __awaiter(this, void 0, void 0, function () {
                var res, favoriteLessonData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/favorite_lessons?page=".concat(page))];
                        case 1:
                            res = _a.sent();
                            favoriteLessonData = res.data || [];
                            return [2 /*return*/, favoriteLessonData.map(function (v) { return new Lesson(v.lesson, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * プログラムをfavする
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "addFavoriteProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/favorite/program', { programId: programId })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * lessonをfavする
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "addFavoriteLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/favorite/lesson', { lessonId: lessonId })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * programのfav解除
     * @param programId
     */
    Object.defineProperty(APIClient.prototype, "unFavoriteProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete("/me/favorite/program/".concat(programId))];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * lessonのfav解除
     * @param lessonId
     */
    Object.defineProperty(APIClient.prototype, "unFavoriteLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete("/me/favorite/lesson/".concat(lessonId))];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * programをfav済みかどうか
     */
    Object.defineProperty(APIClient.prototype, "isFavoritedProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/programs/".concat(id, "/is_favorited"))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * programのレッスンのお気に入り状態一覧を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLessonsFavoriteStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/programs/".concat(id, "/lessons/favorite_status"))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data || []];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "changePlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/subscription_plan_queue', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "changePlanForUnsubscribeCp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/unsubscribe_cp_plan_queue')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "removeQueuedPlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete('/me/subscription_plan_queue')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "getIsQueuedPlanForPricingRevision", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/check_queued_plan_for_pricing_revision')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * メルマガ購読解除
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "sendMagazineUnsubscribeToken", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/unsubscribe_magazine', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * 動画視聴設定を変更する.
     * @param isAutoPlayNextVideo
     */
    Object.defineProperty(APIClient.prototype, "UpdateViewingSetting", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isAutoPlayNextVideo) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/viewing_setting', { isAutoPlayNextVideo: isAutoPlayNextVideo })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * マイページ入力促進モーダルの表示日時を変更する.
     */
    Object.defineProperty(APIClient.prototype, "updateDisplayMypageInputPromotion", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (promotionDisplayedAt) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/display_mypage_input_promotion', { promotionDisplayedAt: promotionDisplayedAt })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * プロフィール画像をアップロードする.
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "uploadProfilePicture", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var data, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = new FormData();
                            data.append('profile_picture', params);
                            return [4 /*yield*/, this.axios.patch('/me/profile_picture', data, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    },
                                })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * トレーナー一覧取得
     */
    Object.defineProperty(APIClient.prototype, "fetchTrainers", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, trainerData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/trainers')];
                        case 1:
                            res = _a.sent();
                            trainerData = res.data || [];
                            return [2 /*return*/, trainerData.map(function (v) { return new Trainer(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * トレーナー単体取得
     * @param trainerId
     */
    Object.defineProperty(APIClient.prototype, "fetchTrainer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (trainerId) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/trainers/".concat(trainerId))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Trainer(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * トレーナーが所属するプログラム取得
     * @param trainerId
     */
    Object.defineProperty(APIClient.prototype, "fetchProgramByTrainer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (trainerId) {
            return __awaiter(this, void 0, void 0, function () {
                var res, porgramData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/instructors/".concat(trainerId, "/programs"))];
                        case 1:
                            res = _a.sent();
                            porgramData = res.data.response || [];
                            return [2 /*return*/, porgramData.map(function (v) { return new Program(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * インストラクターに紐づくプログラム一覧を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchProgramByInstructorID", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (instructorID, page, limit) {
            return __awaiter(this, void 0, void 0, function () {
                var res, porgramData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/instructors/".concat(instructorID, "/programs?page=").concat(page, "&limit=").concat(limit))];
                        case 1:
                            res = _a.sent();
                            porgramData = res.data.response || [];
                            return [2 /*return*/, porgramData.map(function (v) { return new Program(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * インストラクターに紐づくレッスン一覧を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLessonByInstructorID", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (instructorID, filter, page, limit) {
            return __awaiter(this, void 0, void 0, function () {
                var res, lessonData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/instructors/".concat(instructorID, "/lessons?filter=").concat(filter, "&page=").concat(page, "&limit=").concat(limit))];
                        case 1:
                            res = _a.sent();
                            lessonData = res.data.response || [];
                            return [2 /*return*/, lessonData.map(function (v) { return new Lesson(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * インストラクターに紐づくLiveLesson一覧を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLiveLessonByInstructorID", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (instructorID, page, limit) {
            return __awaiter(this, void 0, void 0, function () {
                var res, liveLessonData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/instructors/".concat(instructorID, "/live_lessons?page=").concat(page, "&limit=").concat(limit))];
                        case 1:
                            res = _a.sent();
                            liveLessonData = res.data.response || [];
                            return [2 /*return*/, liveLessonData.map(function (v) { return new LiveLesson(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * インストラクターさんのレッスンが含まれているプレイリスト一覧を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchPlaylistByInstructorID", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (instructorID, page, limit) {
            return __awaiter(this, void 0, void 0, function () {
                var res, playlistData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/playlists?instructor_id=".concat(instructorID, "&page=").concat(page, "&limit=").concat(limit))];
                        case 1:
                            res = _a.sent();
                            playlistData = res.data.response || [];
                            return [2 /*return*/, playlistData.map(function (v) { return new Playlist(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * メール認証
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "verifyEmail", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch("/email_verifications", params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * マッチングテスト一覧取得
     */
    Object.defineProperty(APIClient.prototype, "fetchMatchingAssessments", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, matchingAssessmentData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/course_matching_assessments')];
                        case 1:
                            res = _a.sent();
                            matchingAssessmentData = res.data.response || [];
                            return [2 /*return*/, matchingAssessmentData.map(function (v) { return new MatchingAssessment(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * マッチングテスト結果取得
     */
    Object.defineProperty(APIClient.prototype, "fetchMatchingAssessmentResult", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (choiceIds) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/course_matching_assessment', { choiceIds: choiceIds })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new UserProfile(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    /**
     * 目標設定診断一覧取得
     */
    Object.defineProperty(APIClient.prototype, "fetchGoalMatchingAssessments", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (version) {
            return __awaiter(this, void 0, void 0, function () {
                var res, matchingAssessmentData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/goal_matching_assessments?version=".concat(version))];
                        case 1:
                            res = _a.sent();
                            matchingAssessmentData = res.data.response || [];
                            return [2 /*return*/, matchingAssessmentData.map(function (v) { return new MatchingAssessment(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * 目標設定診断結果取得
     */
    Object.defineProperty(APIClient.prototype, "fetchGoalMatchingAssessmentResult", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/goal_matching_assessments', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new GoalMatchingAssessmentResponse(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    /**
     * 目標設定診断ユーザ回答取得
     */
    Object.defineProperty(APIClient.prototype, "fetchGoalMatchingAssessmentAnswer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (version) {
            return __awaiter(this, void 0, void 0, function () {
                var res, answerData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/goal_matching_assessment_answers?version=".concat(version))];
                        case 1:
                            res = _a.sent();
                            answerData = res.data.response || [];
                            return [2 /*return*/, answerData.map(function (v) { return new GoalMatchingAssessmentAnswerResponse(v); })];
                    }
                });
            });
        }
    });
    /**
     * 目標設定診断で必須項目が回答されているかチェック
     */
    Object.defineProperty(APIClient.prototype, "fetchGoalMatchingAssessmentAnswerCheck", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (version) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/goal_matching_assessment_answers_check?version=".concat(version))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * 目標体重をチェック
     */
    Object.defineProperty(APIClient.prototype, "checkWeightGoal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (height, weight, weightGoal) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/goal_matching_assessments/weight_goal_validation?height=".concat(height, "&weight=").concat(weight, "&weight_goal=").concat(weightGoal))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * 減量ペース詳細取得
     */
    Object.defineProperty(APIClient.prototype, "fetchWeightGoalPace", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (weight, weightGoal, version) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/goal_matching_assessments/weight_goal_pace?weight=".concat(weight, "&weight_goal=").concat(weightGoal, "&version=").concat(version))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * 減量ペース詳細取得
     */
    Object.defineProperty(APIClient.prototype, "fetchWeightGoalApproach", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (activityLevel, gender, birthday, height, weight, weightGoal, paceChoiceId, version) {
            return __awaiter(this, void 0, void 0, function () {
                var urlParams, res, weightGoalApproachResponseData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            urlParams = new URLSearchParams();
                            urlParams.append('activity_level_id', activityLevel.toString());
                            urlParams.append('gender_id', gender.toString());
                            urlParams.append('birthday', birthday);
                            urlParams.append('height', height.toString());
                            urlParams.append('weight', weight.toString());
                            urlParams.append('weight_goal', weightGoal.toString());
                            urlParams.append('pace_choice_id', paceChoiceId.toString());
                            urlParams.append('version', version.toString());
                            return [4 /*yield*/, this.axios.get("/goal_matching_assessments/weight_goal_approach?".concat(urlParams.toString()))];
                        case 1:
                            res = _a.sent();
                            weightGoalApproachResponseData = res.data.response || [];
                            return [2 /*return*/, weightGoalApproachResponseData.map(function (v) { return new WeightGoalApproach(v); })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchBadges", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, badgeData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/badges')];
                        case 1:
                            res = _a.sent();
                            badgeData = res.data || [];
                            return [2 /*return*/, badgeData.map(function (v) { return new Badge(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * ユーザが獲得した未表示のバッジを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchUndisplayedBadges", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, badgeData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/undisplayed_badges')];
                        case 1:
                            res = _a.sent();
                            badgeData = res.data || [];
                            return [2 /*return*/, badgeData.map(function (v) { return new Badge(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * ユーザーのバッジステータス更新
     */
    Object.defineProperty(APIClient.prototype, "updateDisplayedUserBadges", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (badgeIds) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/badges/displayed', { badgeIds: badgeIds })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * プレイリスト一覧を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchPlaylists", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page, sortBy) {
            return __awaiter(this, void 0, void 0, function () {
                var res, playlistData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/playlists?page=".concat(page, "&sort_by=").concat(sortBy))];
                        case 1:
                            res = _a.sent();
                            playlistData = res.data || [];
                            return [2 /*return*/, playlistData.map(function (o) { return new Playlist(o, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * プレイリストを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/playlists/".concat(programId))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Playlist(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * オリジナルプレイリストを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchOriginalPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/original_playlists/".concat(id))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Playlist(res.data, this.config, true)];
                    }
                });
            });
        }
    });
    /**
     * ユーザチャレンジの一覧取得(チャレンジステータスで検索)
     */
    Object.defineProperty(APIClient.prototype, "fetchUserChallengesByChallengeStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (challengeStatus) {
            return __awaiter(this, void 0, void 0, function () {
                var res, userChallengeData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/challenges?challenge_status=".concat(challengeStatus))];
                        case 1:
                            res = _a.sent();
                            userChallengeData = res.data.response || [];
                            return [2 /*return*/, userChallengeData.map(function (v) { return new UserChallenge(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * チャレンジに参加する
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "startChallenge", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId, start, end) {
            return __awaiter(this, void 0, void 0, function () {
                var params, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = { programId: programId, start: start, end: end };
                            return [4 /*yield*/, this.axios.post('/me/challenges', params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new UserChallenge(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * チャレンジ参加ステータスを更新する (ステータス：'in_progress','complete','stopped')
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "updateChallengeStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userChallengeId, programId, status, start, end) {
            return __awaiter(this, void 0, void 0, function () {
                var params, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = { challengeStatus: status, programId: programId, start: start, end: end };
                            return [4 /*yield*/, this.axios.patch("/me/challenges/".concat(userChallengeId), params)];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new UserChallenge(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * レッスン視聴数ランキング取得
     * @param start
     * @param end
     * @param limit
     */
    Object.defineProperty(APIClient.prototype, "fetchWatchedLessonRanking", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (start, end, limit) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/ranking/lessons?start=".concat(start, "&end=").concat(end, "&limit=").concat(limit))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.map(function (v) { return new WatchedLessonRanking(v, _this.config); })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchDailyLessonRanking", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (limit) {
            return __awaiter(this, void 0, void 0, function () {
                var now, start, end;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            now = dayjs().startOf('day');
                            start = now.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
                            end = now.subtract(1, 'second').format('YYYY-MM-DD HH:mm:ss');
                            return [4 /*yield*/, this.fetchWatchedLessonRanking(start, end, limit)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    /**
     * ダイアログを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchDialog", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, dialogData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/dialog')];
                        case 1:
                            res = _a.sent();
                            dialogData = res.data.response;
                            if (!dialogData)
                                return [2 /*return*/, null];
                            return [2 /*return*/, new Dialog(dialogData, this.config)];
                    }
                });
            });
        }
    });
    /**
     * フッター情報を取得
     */
    Object.defineProperty(APIClient.prototype, "fetchFooter", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/navigation_tabs')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Footer(res.data.response, this.localStorage)];
                    }
                });
            });
        }
    });
    /**
     * 性別リストを取得.
     */
    Object.defineProperty(APIClient.prototype, "fetchGenders", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/genders')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * 都道府県リストを取得.
     */
    Object.defineProperty(APIClient.prototype, "fetchPrefectures", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/prefectures')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.map(function (v) {
                                    return { name: v.name, value: v.value };
                                })];
                    }
                });
            });
        }
    });
    /**
     * 普段の運動量リストを取得.
     */
    Object.defineProperty(APIClient.prototype, "fetchActivityLevels", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/activity_level')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * 悩みリストを取得.
     */
    Object.defineProperty(APIClient.prototype, "fetchWorries", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/worries')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.map(function (v) {
                                    return { name: v.content, value: "".concat(v.id) };
                                })];
                    }
                });
            });
        }
    });
    /**
     * 興味リストを取得.
     */
    Object.defineProperty(APIClient.prototype, "fetchInterests", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/interests')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.map(function (v) {
                                    return { name: v.content, value: "".concat(v.id) };
                                })];
                    }
                });
            });
        }
    });
    /**
     * コース系
     */
    Object.defineProperty(APIClient.prototype, "fetchCourse", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/courses/".concat(id))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Course(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCourses", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (sortBy) {
            return __awaiter(this, void 0, void 0, function () {
                var queryParams, res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            queryParams = sortBy ? "?sort_by=".concat(sortBy) : '';
                            return [4 /*yield*/, this.axios.get("/courses".concat(queryParams))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) {
                                    return new Course(v, _this.config);
                                })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchMyCourse", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("me/course")];
                        case 1:
                            res = _a.sent();
                            if (!res.data.response) {
                                return [2 /*return*/, null];
                            }
                            return [2 /*return*/, new Course(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "selectCourse", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post("me/course", { course_id: id })];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCourseVolume", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("me/course_volume/".concat(id))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new CourseVolume(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCourseDay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (targetDate) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/course_day?target_date=".concat(getFormattedToFormat(targetDate)))];
                        case 1:
                            res = _a.sent();
                            if (!res.data.response) {
                                return [2 /*return*/, null];
                            }
                            return [2 /*return*/, new CourseDay(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "skipCourseDay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (courseDayId) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.put('/me/course_day/skip', { courseDayId: courseDayId })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new CourseDay(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCourseSchedules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("me/course_schedules")];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) {
                                    return new CourseSchedule(v);
                                })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "patchCourseSchedules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (courseSchedules) {
            return __awaiter(this, void 0, void 0, function () {
                var requestCourseSchedules, request;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            requestCourseSchedules = courseSchedules.filter(function (v) { return v.isSelected; });
                            request = { courseSchedules: requestCourseSchedules };
                            return [4 /*yield*/, this.axios.patch("/me/course_schedules", request)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCourseMatchingAssessmentResult", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (choiceIDs, courseInstructorCode) {
            return __awaiter(this, void 0, void 0, function () {
                var queryParams, res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            queryParams = "choice_ids=".concat(choiceIDs);
                            if (courseInstructorCode) {
                                queryParams += "&course_instructor_code=".concat(courseInstructorCode);
                            }
                            return [4 /*yield*/, this.axios.get("/course_matching_assessment_result?".concat(queryParams))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) {
                                    return new Course(v, _this.config);
                                })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCourseDayBy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (courseDaysLessonID) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/course_day/".concat(courseDaysLessonID))];
                        case 1:
                            res = _a.sent();
                            if (!res.data.response) {
                                return [2 /*return*/, null];
                            }
                            return [2 /*return*/, new CourseDay(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "resetCourse", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.put("/me/course/reset")];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "changeCourse", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (courseID) {
            return __awaiter(this, void 0, void 0, function () {
                var request;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            request = { course_id: courseID };
                            return [4 /*yield*/, this.axios.patch("/me/course", request)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCustomWorkouts", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (keyword) {
            return __awaiter(this, void 0, void 0, function () {
                var urlParams, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            urlParams = keyword === '' ? '' : "?q=".concat(keyword);
                            return [4 /*yield*/, this.axios.get("/custom_workouts/search".concat(urlParams))];
                        case 1:
                            res = _a.sent();
                            if (!res.data.response) {
                                return [2 /*return*/, null];
                            }
                            return [2 /*return*/, res.data.response.map(function (v) {
                                    return new CustomWorkout(v);
                                })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCalorie", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (customWorkoutID, duration) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/custom_workouts/calorie?custom_workout_id=".concat(customWorkoutID, "&duration=").concat(duration))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "postCustomWorkout", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/custom_workouts', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "updateCustomWorkout", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id, params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch("/me/custom_workouts/".concat(id), params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "deleteCustomWorkout", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete("/me/custom_workouts/".concat(id))];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /*------ Accountクローズ系 ------*/
    /**
     * 退会予約
     */
    Object.defineProperty(APIClient.prototype, "scheduleUnsubscribe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/unsubscribe')];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * 退会予約取り消し
     */
    Object.defineProperty(APIClient.prototype, "cancelUnsubscribe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete('/me/unsubscribe')];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * Androidアカウント削除
     */
    Object.defineProperty(APIClient.prototype, "destroyAccount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete('/me/destroy_user_relation')];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * 休眠会員
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "dormantAccount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (month) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/me/dormant', { months: month })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * 休眠会員から復活
     */
    Object.defineProperty(APIClient.prototype, "reactivateAccount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete('/me/dormant')];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * パスワードリセットのリクエスト(パスワードを変更したい、ということ)
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "requestResetPassword", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/password_resets', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * パスワードをリセットする。(本変更)
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "resetPassword", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch('/password_resets', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /*------ Plan系 ------*/
    /**
     * Plan取得
     * @param stripePlanID
     */
    Object.defineProperty(APIClient.prototype, "fetchPlanByStripePlanID", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (stripePlanID) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/plans/".concat(stripePlanID))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Plan(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * 登録用Plan取得
     */
    Object.defineProperty(APIClient.prototype, "fetchRegistablePlans", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (useOldPlan) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/base_plans?payment_channels=stripe,amazon_pay&use_old_plan=".concat(+useOldPlan))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) { return new BasePlan(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * 更新用Plan取得
     */
    Object.defineProperty(APIClient.prototype, "fetchChangeablePlans", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, planData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('me/plans/changeable')];
                        case 1:
                            res = _a.sent();
                            planData = res.data || [];
                            return [2 /*return*/, planData.map(function (v) { return new Plan(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * 退会時の割引CPのPlan取得
     */
    Object.defineProperty(APIClient.prototype, "fetchChangeableUnsubscribeCpPlans", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res, planData;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('me/plans/changeable_unsubscribe_cp')];
                        case 1:
                            res = _a.sent();
                            planData = res.data || [];
                            return [2 /*return*/, planData.map(function (v) { return new Plan(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * キャンペーンコードをもとにPlan取得
     */
    Object.defineProperty(APIClient.prototype, "fetchCampaignWithPlans", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (code) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/campaign_base_plans/".concat(code))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Campaign(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    /**
     * キャンペーンコードをもとにPlan取得
     */
    Object.defineProperty(APIClient.prototype, "fetchAmazonPayPlanForSignUp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/amazon_pay_plan_for_signup')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new AmazonPayPlan(res.data.response)];
                    }
                });
            });
        }
    });
    /**
     * 参加予定のライブレッスンを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchSubscribedLiveLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/live_lessons/subscribe")];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (live) { return new LiveLesson(live, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * ライブレッスンに参加する予定かどうかを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLiveLessonAttendanceStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (liveLessonId) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/live_lessons/".concat(liveLessonId, "/is_subscribed"))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * ライブレッスンに参加する予定にする
     */
    Object.defineProperty(APIClient.prototype, "attendLiveLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (liveLessonId) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post("/me/live_lessons/".concat(liveLessonId, "/subscribe"))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * ライブレッスンに参加しない予定にする
     */
    Object.defineProperty(APIClient.prototype, "absentLiveLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (liveLessonId) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete("/me/live_lessons/".concat(liveLessonId, "/unsubscribe"))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * 開催日時で絞ってライブレッスンを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLiveLessonsByStartsAt", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (from, to) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/live_lessons?start_at_from=".concat(from, "&start_at_to=").concat(to))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (live) { return new LiveLesson(live, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * 見逃し配信中のライブレッスンを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchRebroadcastLiveLessons", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/live_lessons/rebroadcast')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (live) { return new LiveLesson(live, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * 単一ライブレッスンを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchLiveLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.axios
                            .get("me/live_lessons/".concat(id))
                            .then(function (v) {
                            if (!v)
                                return null;
                            return new LiveLesson(v.data.response, _this.config);
                        })
                            .catch(function (e) {
                            if (e.isNotFoundError)
                                return null;
                            throw e;
                        })];
                });
            });
        }
    });
    /**
     * 次のライブレッスンを取得
     */
    Object.defineProperty(APIClient.prototype, "fetchNextLiveLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/live_lessons/next")];
                        case 1:
                            res = _a.sent();
                            if (!res.data)
                                return [2 /*return*/, null];
                            return [2 /*return*/, new LiveLesson(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * 動画の検索
     */
    Object.defineProperty(APIClient.prototype, "search", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (searchParams_1) {
            return __awaiter(this, arguments, void 0, function (searchParams, page) {
                var urlParams, res;
                if (page === void 0) { page = 0; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            urlParams = new URLSearchParams();
                            urlParams.append('page', page.toString());
                            if (searchParams) {
                                if (searchParams.sortBy)
                                    urlParams.append('sort_by', searchParams.sortBy);
                                if (searchParams.category)
                                    urlParams.append('category', searchParams.category);
                                if (searchParams.q)
                                    urlParams.append('q', searchParams.q);
                                if (searchParams.styleId)
                                    urlParams.append('style_id', searchParams.styleId);
                                if (searchParams.difficultyId)
                                    urlParams.append('difficulty_id', searchParams.difficultyId);
                                if (searchParams.durationId)
                                    urlParams.append('duration_id', searchParams.durationId);
                            }
                            return [4 /*yield*/, this.axios.get("/search_contents?".concat(urlParams.toString()))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new SearchResult(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    /**
     * Facebook Conversion APIにデータを送信
     * @param params
     */
    Object.defineProperty(APIClient.prototype, "postConversionToFB", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/conversion', params)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * お知らせ一覧取得
     */
    Object.defineProperty(APIClient.prototype, "fetchAnnouncements", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/announcements?page=".concat(page))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Announcements(res.data, this.config)];
                    }
                });
            });
        }
    });
    /**
     * 対象のお知らせ取得
     */
    Object.defineProperty(APIClient.prototype, "getAnnouncement", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.axios
                            .get("/me/announcements/".concat(id))
                            .then(function (v) {
                            if (!v)
                                return null;
                            return new Announcement(v.data, _this.config);
                        })
                            .catch(function (e) {
                            if (e.isNotFoundError)
                                return null;
                            throw e;
                        })];
                });
            });
        }
    });
    /**
     * 対象のお知らせを既読にする
     */
    Object.defineProperty(APIClient.prototype, "updateAnnouncementReadInformation", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post("/me/announcements/".concat(id))];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * 全ての未読お知らせを既読にする
     */
    Object.defineProperty(APIClient.prototype, "updateAllAnnouncementReadInformation", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.patch("/me/announcements")];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * お知らせ未読情報取得
     */
    Object.defineProperty(APIClient.prototype, "getUnreadAnnouncementCount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/unread_announcement')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * オリジナルプレイリスト作成
     */
    Object.defineProperty(APIClient.prototype, "createOriginalPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (name, lessonIds, file, description) {
            return __awaiter(this, void 0, void 0, function () {
                var data, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = new FormData();
                            data.append('name', name);
                            lessonIds.forEach(function (id, i) {
                                data.append("lesson_ids[".concat(i, "]"), id.toString());
                            });
                            data.append('thumbnail_file', file);
                            data.append('description', description);
                            return [4 /*yield*/, this.axios.post('/me/original_playlists', data, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    },
                                })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.id];
                    }
                });
            });
        }
    });
    /**
     * オリジナルプレイリスト更新
     */
    Object.defineProperty(APIClient.prototype, "updateOriginalPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (name, lessonIds, file, description, id) {
            return __awaiter(this, void 0, void 0, function () {
                var data, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = new FormData();
                            data.append('name', name);
                            lessonIds.forEach(function (id, i) {
                                data.append("lesson_ids[".concat(i, "]"), id.toString());
                            });
                            if (file)
                                data.append('thumbnail_file', file);
                            data.append('description', description);
                            return [4 /*yield*/, this.axios.patch("/me/original_playlists/".concat(id), data, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    },
                                })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * オリジナルプレイリスト削除
     */
    Object.defineProperty(APIClient.prototype, "deleteOriginalPlaylist", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.delete("/me/original_playlists/".concat(id))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * オリジナルプレイリスト一覧取得
     */
    Object.defineProperty(APIClient.prototype, "getOriginalPlaylists", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/original_playlists')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.map(function (o) { return new Playlist(o, _this.config, true); })];
                    }
                });
            });
        }
    });
    /**
     * 検索のキーワード一覧取得
     */
    Object.defineProperty(APIClient.prototype, "getKeywords", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/keywords?type=hotkeyword')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data];
                    }
                });
            });
        }
    });
    /**
     * ホームに表示するバナー取得
     */
    Object.defineProperty(APIClient.prototype, "getHomeBanners", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/home_banners")];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) { return new Banner(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * ライブ配信ページに表示するバナー取得
     */
    Object.defineProperty(APIClient.prototype, "getLiveLessonBanners", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/live_lesson_banners")];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) { return new Banner(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * ランク一覧取得
     */
    Object.defineProperty(APIClient.prototype, "getUserSeasonAggregates", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/user_season_aggregates')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) { return new UserSeasonAggregate(v, _this.config); })];
                    }
                });
            });
        }
    });
    /**
     * TwitterのOauth認証が完了しているか確認
     */
    Object.defineProperty(APIClient.prototype, "confirmTwitterAuthorization", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/me/tw/confirm_authorization')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * TwitterのOauth認証画面URL取得
     */
    Object.defineProperty(APIClient.prototype, "twitterAuthorization", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/tw/authorization')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * Twitter投稿実行
     */
    Object.defineProperty(APIClient.prototype, "postTweet", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params, file) {
            return __awaiter(this, void 0, void 0, function () {
                var data, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            data = new FormData();
                            data.append('tweet_text', params.text);
                            data.append('img_file', file);
                            return [4 /*yield*/, this.axios.post('/me/tw/post', data, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    },
                                })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * 指定日の最新体重の取得
     */
    Object.defineProperty(APIClient.prototype, "fetchUserWeight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (date) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/target_weight?target_date=".concat(getFormattedToFormat(date)))];
                        case 1:
                            res = _a.sent();
                            if (!res.data.response) {
                                return [2 /*return*/, null];
                            }
                            return [2 /*return*/, new UserWeight(res.data.response)];
                    }
                });
            });
        }
    });
    /**
     * 体重ログのリクエスト
     */
    Object.defineProperty(APIClient.prototype, "postUserWeight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (weight, date) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/weight', { weight: weight, date: getFormattedToFormat(date) })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * 1日毎のレッスン時間合計
     */
    Object.defineProperty(APIClient.prototype, "getLessonSessionByDaily", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/lesson_session_by_daily?page=".concat(page))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.userLessonSessionGroupByDaily.map(function (v) { return new UserLessonSessionGroupByDaily(v); })];
                    }
                });
            });
        }
    });
    /**
     * 体重ログ一覧
     */
    Object.defineProperty(APIClient.prototype, "getUserWeights", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page, limit) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/weight?page=".concat(page, "&limit=").concat(limit))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.userWeights.map(function (v) { return new UserWeight(v); })];
                    }
                });
            });
        }
    });
    /**
     * 友達紹介キャンペーン情報取得
     */
    Object.defineProperty(APIClient.prototype, "getFriendInvitationCampaign", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/friend_invitation_campaign")];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new FriendInvitationCampaign(res.data.response)];
                    }
                });
            });
        }
    });
    /**
     * AmazonPay決済でクレカ登録
     */
    Object.defineProperty(APIClient.prototype, "registerAmazonPay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (sessionId, planId, invitationCode) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/me/register_amazon_pay', { sessionId: sessionId, planId: planId, invitationCode: invitationCode })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /**
     * AmazonPay決済で新規登録
     */
    Object.defineProperty(APIClient.prototype, "signupForAmazonPay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (sessionId) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/signup_from_amazon_pay', { sessionId: sessionId })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * AmazonPay決済完了用のリダイレクトURLを取得
     */
    Object.defineProperty(APIClient.prototype, "checkoutFromAmazonPay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (sessionId, planId) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post('/checkout_from_amazon_pay', { sessionId: sessionId, planId: planId })];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    /**
     * 商品一覧を取得
     */
    Object.defineProperty(APIClient.prototype, "getMerchandises", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/merchandises?page=".concat(page))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) { return new Merchandise(v, _this.config); })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchReward", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (courseDayID) {
            return __awaiter(this, void 0, void 0, function () {
                var queryParams, res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            queryParams = courseDayID ? "?course_day_id=".concat(courseDayID) : '';
                            return [4 /*yield*/, this.axios.get("/me/rewards".concat(queryParams))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new Reward(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchCourseDaySnsShareContent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (courseDayID) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/rewards/share_contents?course_day_id=".concat(courseDayID))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new SnsShareContent(res.data.response)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "postCourseDayReview", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (courseDayID, isGood) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.post("me/course_day/".concat(courseDayID, "/reviews"), { isGood: isGood })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchWorkoutCalories", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (startDate, page, limit) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/workout_calories?start_date=".concat(getFormattedToFormat(startDate), "&page=").concat(page, "&limit=").concat(limit))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) { return new WorkoutCalories(v); })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchStepActivities", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (startDate, page, limit) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/step_activities?start_date=".concat(getFormattedToFormat(startDate), "&page=").concat(page, "&limit=").concat(limit))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) { return new StepActivity(v); })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchStepGoals", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/step_goals')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "saveStepGoal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (steps) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.put('/me/step_goal', { steps: steps })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchWorkoutGoals", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get('/workout_goals')];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response.map(function (v) {
                                    return { name: v.targetCalories.toString(), value: v.id.toString() };
                                })];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "saveWorkoutGoal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (workoutGoalId) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.put('/me/workout_goal', { workoutGoalId: workoutGoalId })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "saveWeightGoal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (weightGoal) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.put('/me/weight_goal', { weightGoal: weightGoal })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchDailyWorkoutTask", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (date) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/daily_tasks/workout?date=".concat(date))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new DailyWorkoutTask(res.data.response, this.config)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchDailyStepsTask", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (date) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/daily_tasks/steps?date=".concat(date))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new DailyStepsTask(res.data.response)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchDailyTasks", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (date) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/daily_tasks?date=".concat(getFormattedToFormat(date)))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new DailyTasks(res.data.response)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchDailyTaskGoal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (taskName, date) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/daily_tasks/".concat(taskName, "/goal?date=").concat(date))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchMealScores", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (date) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/meal_scores?date=".concat(getFormattedToFormat(date)))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, new MealScore(res.data.response)];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchMealNutrients", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (date, type) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/meal_balances?date=".concat(getFormattedToFormat(date), "&type=").concat(type))];
                        case 1:
                            res = _b.sent();
                            return [2 /*return*/, {
                                    isEntered: res.data.response.isEntered,
                                    nutrients: ((_a = res.data.response.nutrients) === null || _a === void 0 ? void 0 : _a.map(function (v) { return new Nutrient(v); })) || [],
                                }];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchMealMenus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (keyword) {
            return __awaiter(this, void 0, void 0, function () {
                var res, mealMenu;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/meal_menus?q=".concat(keyword))];
                        case 1:
                            res = _b.sent();
                            mealMenu = ((_a = res.data.response.menus) === null || _a === void 0 ? void 0 : _a.map(function (mealMenuData) { return new MealMenu(mealMenuData); })) || [];
                            return [2 /*return*/, { mealMenu: mealMenu, error: res.data.response.calomealErrMessage }];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "fetchMealHistories", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (type) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/meal_histories?type=".concat(type))];
                        case 1:
                            res = _b.sent();
                            return [2 /*return*/, ((_a = res.data.response.menus) === null || _a === void 0 ? void 0 : _a.map(function (mealMenuData) { return new MealMenu(mealMenuData); })) || []];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "skipMeal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (type, date) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.put("/me/skip_user_meal", { type: type, date: date })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "saveMeal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (date, time, type, menus) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.put("/me/user_meal", { date: date, time: time, type: type, menus: menus })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(APIClient.prototype, "getMeal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (date, type) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.axios.get("/me/user_meal?date=".concat(date, "&type=").concat(type))];
                        case 1:
                            res = _a.sent();
                            return [2 /*return*/, res.data.response ? new UserMeal(res.data.response) : null];
                    }
                });
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "appendToSequence", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "startLiveLessonWatchingRecord", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number, Number, Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "finishLiveLessonWatchingRecord", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number, Number, Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "syncLiveLessonWatchingRecord", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "patchProfile", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number, Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "fetchDailyActivity", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "addFavoriteProgram", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "addFavoriteLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "unFavoriteProgram", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "unFavoriteLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "updateDisplayedUserBadges", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "fetchSubscribedLiveLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "fetchLiveLessonAttendanceStatus", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "attendLiveLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "absentLiveLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "fetchLiveLesson", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "getLessonSessionByDaily", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "getUserWeights", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], APIClient.prototype, "getFriendInvitationCampaign", null);
    return APIClient;
}());
export { APIClient };
