var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Payment } from '.';
import { AuthStatus, AuthMethod, } from '@lean-body/src/interfaces';
import { LoggedInPlatForm } from '@lean-body/src/infra';
import { routes } from '../route';
import { bind } from 'bind-decorator';
/*=============================================
= Service =
=============================================*/
// アカウントサービス。meのキャッシュと認証や退会などログイン情報に関わる箇所を変更するサービス
var AccountService = /** @class */ (function () {
    function AccountService(authState, apiClient, fbClient, lineClient, appleClient, localStorage, navigate, config) {
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fbClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lineClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "appleClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "authState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "localStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "meRepo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "navigate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        this.apiClient = apiClient;
        this.fbClient = fbClient;
        this.lineClient = lineClient;
        this.appleClient = appleClient;
        this.authState = authState;
        this.localStorage = localStorage;
        this.meRepo = new MeRepository(apiClient, config);
        this.navigate = navigate;
    }
    /*---------- Query ----------*/
    Object.defineProperty(AccountService.prototype, "fetchMe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (p) {
            return __awaiter(this, void 0, void 0, function () {
                var me;
                var _this = this;
                return __generator(this, function (_a) {
                    me = this.meRepo.get(p);
                    me.then(function (u) {
                        _this.userId = u.id;
                    });
                    return [2 /*return*/, me];
                });
            });
        }
    });
    // AuthorizationAPIへのリクエストとauthStateの更新
    Object.defineProperty(AccountService.prototype, "authorization", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.apiClient
                            .authorization()
                            .then(function (v) {
                            _this.authState.setAuthed();
                            _this.localStorage.saveLoginState(true);
                            return v;
                        })
                            .catch(function (e) {
                            if (e.isForbiddenError) {
                                var forbiddenError = e;
                                if (forbiddenError.isDormant)
                                    _this.authState.setDormant();
                                else if (forbiddenError.isFuture)
                                    _this.authState.setFuture();
                                else if (forbiddenError.isPaymentNotFound || forbiddenError.isPaymentExpired)
                                    _this.authState.setNosubscription();
                            }
                            throw e;
                        })];
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "reloadMe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.meRepo.reloadCache();
        }
    });
    Object.defineProperty(AccountService.prototype, "clearMe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.meRepo.clearCache();
        }
    });
    Object.defineProperty(AccountService.prototype, "isPaymentRegistered", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var me;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.fetchMe()];
                        case 1:
                            me = _a.sent();
                            return [2 /*return*/, me.paymentRegistered];
                    }
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "loggedIn", {
        // ログインしているか
        get: function () {
            return !!this.localStorage.loadLoginState();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountService.prototype, "authed", {
        /**
         * 認証されているか取得
         */
        get: function () {
            return this.authStatus === AuthStatus.Authed;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AccountService.prototype, "authStatus", {
        /**
         * 認証状態を取得
         */
        get: function () {
            return this.authState.authStatus;
        },
        enumerable: false,
        configurable: true
    });
    /*---------- Command ----------*/
    Object.defineProperty(AccountService.prototype, "signUp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params, tracker) {
            return __awaiter(this, void 0, void 0, function () {
                var result, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.apiClient.signUp({
                                    email: params.email,
                                    password: params.password,
                                })];
                        case 1:
                            result = _a.sent();
                            this.onAuthed(result, tracker, AuthMethod.Email);
                            return [3 /*break*/, 3];
                        case 2:
                            err_1 = _a.sent();
                            this.onUnAuthed();
                            throw err_1;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "fbSigInWithAndroid", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (token_1, tracker_1) {
            return __awaiter(this, arguments, void 0, function (token, tracker, isSignUp) {
                var auth;
                if (isSignUp === void 0) { isSignUp = false; }
                return __generator(this, function (_a) {
                    auth = {
                        accessToken: token,
                        expiresIn: 0,
                        signedRequest: '',
                        userID: '',
                    };
                    return [2 /*return*/, this.postFBAccessToken(auth, isSignUp, tracker)];
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "fbSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tracker_1) {
            return __awaiter(this, arguments, void 0, function (tracker, isSignUp) {
                var auth;
                if (isSignUp === void 0) { isSignUp = false; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.fbClient.auth) return [3 /*break*/, 1];
                            return [2 /*return*/, this.postFBAccessToken(this.fbClient.auth, isSignUp, tracker)];
                        case 1: return [4 /*yield*/, this.fbClient.login()];
                        case 2:
                            auth = _a.sent();
                            return [2 /*return*/, this.postFBAccessToken(auth, isSignUp, tracker)];
                    }
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "lineSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tracker) {
            return __awaiter(this, void 0, void 0, function () {
                var params, result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            params = {
                                oauthCode: this.lineClient.oauthCode,
                            };
                            return [4 /*yield*/, this.apiClient.lineSignIn(params)];
                        case 1:
                            result = _a.sent();
                            this.onAuthed(result, tracker, AuthMethod.LINE);
                            this.localStorage.saveLoggedInPlatform(LoggedInPlatForm.line);
                            return [2 /*return*/, result];
                    }
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "amazonPaySignup", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (amazonCheckoutSessionId, tracker) {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.signupForAmazonPay(amazonCheckoutSessionId)
                            // AmazonPayでSingupしているが、サーバー側ではEmailでのログインとして処理していて、SigninページにAmazonPayボタンは実装していない
                            // 将来、Amazonログインを実装した時、AuthMethod.Amazonを実装する
                        ];
                        case 1:
                            res = _a.sent();
                            // AmazonPayでSingupしているが、サーバー側ではEmailでのログインとして処理していて、SigninページにAmazonPayボタンは実装していない
                            // 将来、Amazonログインを実装した時、AuthMethod.Amazonを実装する
                            this.onAuthed(res, tracker, AuthMethod.Email);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "getNonce", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.apiClient.nonce()];
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "postFBAccessToken", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (auth, isSignUp, tracker) {
            return __awaiter(this, void 0, void 0, function () {
                var res, err_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.apiClient.fbSignIn({ accessToken: auth.accessToken })];
                        case 1:
                            res = _a.sent();
                            this.onAuthed(res, tracker, AuthMethod.Facebook);
                            this.localStorage.saveLoggedInPlatform(LoggedInPlatForm.facebook);
                            return [2 /*return*/, res];
                        case 2:
                            err_2 = _a.sent();
                            this.onUnAuthed();
                            throw err_2;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "signIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params, tracker) {
            return __awaiter(this, void 0, void 0, function () {
                var result, err_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, this.apiClient.signIn(params)];
                        case 1:
                            result = _a.sent();
                            return [4 /*yield*/, this.onAuthed(result, tracker, AuthMethod.Email)];
                        case 2:
                            _a.sent();
                            this.localStorage.resetLoggedInPlatform();
                            return [3 /*break*/, 4];
                        case 3:
                            err_3 = _a.sent();
                            this.onUnAuthed();
                            throw err_3;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "signOut", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.signOut()];
                        case 1:
                            _a.sent();
                            this.onUnAuthed();
                            this.navigate(routes.SIGNIN.compile());
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /*
     * 退会予約
     */
    Object.defineProperty(AccountService.prototype, "scheduleUnsubscribe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.scheduleUnsubscribe()];
                        case 1:
                            _a.sent();
                            this.meRepo.clearCache();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /*
     * Androidアカウント削除
     */
    Object.defineProperty(AccountService.prototype, "destroyAccount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.destroyAccount()];
                        case 1:
                            _a.sent();
                            this.onUnAuthed();
                            this.navigate(routes.SIGNIN.compile());
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // 休眠会員になる
    Object.defineProperty(AccountService.prototype, "dormantAccount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (month) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.apiClient.dormantAccount(month).then(function () {
                            _this.meRepo.reloadCache();
                            _this.authState.setDormant();
                        })];
                });
            });
        }
    });
    // 休眠会員から復活
    Object.defineProperty(AccountService.prototype, "reactivateAccount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.apiClient.reactivateAccount().then(function () {
                            _this.meRepo.clearCache();
                            _this.authState.setUndormant();
                        })];
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "registerPayment", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (token, planID, corporationEmployeeParams, invitationCode) {
            return __awaiter(this, void 0, void 0, function () {
                var rs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.apiClient.registerPayment({
                                sourceToken: token,
                                subPlanID: planID,
                                corporationEmployee: !corporationEmployeeParams
                                    ? null
                                    : corporationEmployeeParams.toJson(),
                                invitationCode: invitationCode,
                            })];
                        case 1:
                            rs = _a.sent();
                            this.afterCCRegister();
                            return [2 /*return*/, rs];
                    }
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "afterCCRegister", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var user;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.meRepo.get()];
                        case 1:
                            user = _a.sent();
                            this.userId = user.id;
                            this.authState.setAuthed();
                            return [4 /*yield*/, this.meRepo.reloadCache()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // SignInWithAppleを利用して認証を行う
    Object.defineProperty(AccountService.prototype, "signInWithApple", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (clientId, redirectUri) {
            return __awaiter(this, void 0, void 0, function () {
                var nonce;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.getNonce()];
                        case 1:
                            nonce = (_a.sent()).nonce;
                            return [4 /*yield*/, this.appleClient.authenticate(nonce, clientId, redirectUri)];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    // SignInWithApple認証後の設定
    Object.defineProperty(AccountService.prototype, "onAuthedSignInWithApple", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (isSignUp, didSubExpired, tracker) {
            return __awaiter(this, void 0, void 0, function () {
                var authResult;
                return __generator(this, function (_a) {
                    this.localStorage.saveLoggedInPlatform(LoggedInPlatForm.apple);
                    this.authState.setAuthed();
                    if (didSubExpired)
                        this.authState.setNosubscription();
                    authResult = {
                        isSignUp: isSignUp,
                    };
                    this.onAuthed(authResult, tracker, AuthMethod.Apple);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(AccountService.prototype, "onUnAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.meRepo.clearCache();
            this.authState.setUnAuthed();
            this.localStorage.saveLoginState(false);
        }
    });
    /*---------- private methods ----------*/
    Object.defineProperty(AccountService.prototype, "onAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (result, tracker, authMethod) {
            return __awaiter(this, void 0, void 0, function () {
                var email, me;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.localStorage.saveLoginState(true);
                            if (!result.user) return [3 /*break*/, 1];
                            this.userId = result.user.id;
                            this.meRepo.set(result.user);
                            email = result.user.email;
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, this.fetchMe()];
                        case 2:
                            me = _a.sent();
                            this.userId = me.id;
                            email = me.email;
                            _a.label = 3;
                        case 3:
                            tracker.userId = this.userId;
                            if (result.isSignUp) {
                                this.authState.setNosubscription();
                                tracker.trackSignUp(this.userId, email);
                            }
                            else {
                                tracker.trackSignIn(this.userId, email, authMethod);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", Promise)
    ], AccountService.prototype, "dormantAccount", null);
    return AccountService;
}());
export { AccountService };
/*=============================================
= Repositories =
=============================================*/
var Me = /** @class */ (function () {
    function Me(meData, config) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDormant", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "email", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "payment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "passwordEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "invoiceFailed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unsubscribeScheduledAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "deletedAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "createdAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = meData.id;
        this.email = meData.email;
        this.userName = meData.userName;
        this.isDormant = meData.isDormant;
        this.payment = meData.payment ? new Payment(meData.payment, config) : null;
        this.passwordEnabled = meData.passwordEnabled;
        this.invoiceFailed = meData.invoiceFailed;
        this.unsubscribeScheduledAt = meData.unsubscribeScheduledAt ? meData.unsubscribeScheduledAt : null;
        this.deletedAt = meData.deletedAt ? meData.deletedAt : null;
        this.createdAt = meData.createdAt ? meData.createdAt : null;
    }
    Object.defineProperty(Me.prototype, "paymentRegistered", {
        get: function () {
            return !!this.payment;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Me.prototype, "isLineUser", {
        /**
         * LINE登録ユーザーかどうか判定
         * @todo APIからOAuthの情報も一緒に返すようにして判定
         */
        get: function () {
            // line登録ユーザーはメアドにlb-tmpの文字列が含まれる
            return this.email.indexOf('lb-tmp') != -1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Me.prototype, "canChangePlans", {
        /*
         * プラン変更ができるか判定
         *
         * 変更できないケース：
         * 1. 退会をしてない
         * 2. 休眠会員ではない
         * 3. すでに切り替えのプランがある
         * 4. 新（2024年価格改定）キャンペーンプランではない
         */
        get: function () {
            return (this.payment &&
                !this.unsubscribeScheduledAt &&
                !this.deletedAt &&
                !this.isDormant &&
                !this.payment.queuedPlan &&
                !(this.payment.currentPlan.isPlanAfter2024May15PriceChange && this.payment.currentPlan.isCampaignPlan()));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Me.prototype, "isDormantAvailable", {
        get: function () {
            return (!this.isDormant &&
                !this.unsubscribeScheduledAt &&
                this.payment &&
                (this.payment.isStripePayment() || this.payment.isAmazonPayUser()) &&
                !this.payment.isFreeTrialUser() &&
                !this.payment.isBillingGrace);
        },
        enumerable: false,
        configurable: true
    });
    return Me;
}());
export { Me };
var MeRepository = /** @class */ (function () {
    function MeRepository(apiClient, config) {
        Object.defineProperty(this, "apiClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cache", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "fetchMeDeffered", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.apiClient = apiClient;
        this.config = config;
    }
    Object.defineProperty(MeRepository.prototype, "get", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (p) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    if (this.cache && !(p === null || p === void 0 ? void 0 : p.nocache)) {
                        return [2 /*return*/, this.cache];
                    }
                    this.fetchMeDeffered = this.apiClient
                        .fetchMe()
                        .then(function (meData) {
                        var me = new Me(meData, _this.config);
                        _this.cache = me;
                        return me;
                    })
                        .catch(function (err) {
                        _this.clearCache();
                        throw err;
                    });
                    return [2 /*return*/, this.fetchMeDeffered];
                });
            });
        }
    });
    Object.defineProperty(MeRepository.prototype, "set", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (meData) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.cache = new Me(meData, this.config);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(MeRepository.prototype, "reloadCache", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.clearCache();
                            return [4 /*yield*/, this.get()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(MeRepository.prototype, "clearCache", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.cache = null;
        }
    });
    return MeRepository;
}());
export { MeRepository };
/*=============================================
= Domain Models =
=============================================*/
var AuthState = /** @class */ (function () {
    function AuthState() {
        Object.defineProperty(this, "authStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: AuthStatus.Unauth
        });
    }
    Object.defineProperty(AuthState.prototype, "setAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.authStatus = AuthStatus.Authed;
        }
    });
    Object.defineProperty(AuthState.prototype, "setUnAuthed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.authStatus = AuthStatus.Unauth;
        }
    });
    Object.defineProperty(AuthState.prototype, "setDormant", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.authStatus = AuthStatus.Dormant;
        }
    });
    Object.defineProperty(AuthState.prototype, "setUndormant", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.authStatus = AuthStatus.Authed;
        }
    });
    Object.defineProperty(AuthState.prototype, "setFuture", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.authStatus = AuthStatus.Future;
        }
    });
    Object.defineProperty(AuthState.prototype, "setNosubscription", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.authStatus = AuthStatus.NoSubscription;
        }
    });
    return AuthState;
}());
export { AuthState };
