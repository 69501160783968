var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SessionStorage, } from '@lean-body/src/infra';
import { SnsProvider, } from '@lean-body/src/interfaces';
import { UserProfile } from './user_profile';
import bind from 'bind-decorator';
import { getUserAgent, randomString } from '../util';
import Cookies from 'universal-cookie';
// 各種行動イベントのトラッキングを担う。
var Tracker = /** @class */ (function () {
    function Tracker(storage, fbPixelClient, cloudFunctionClient, gaClient, lambdaFunctionClient, config) {
        Object.defineProperty(this, "fbPixelClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cloudFunctionClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tagClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "gaClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "storage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lambdaFunctionClient", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "session", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "userId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "sessionStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "playHistory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "recommendClickedLesson", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        this.storage = storage;
        this.fbPixelClient = fbPixelClient;
        this.gaClient = gaClient;
        this.cloudFunctionClient = cloudFunctionClient;
        this.lambdaFunctionClient = lambdaFunctionClient;
        this.config = config;
        this.sessionStorage = new SessionStorage();
        this.setSession();
        this.setReferrer();
        this.setInitialReferrer();
    }
    Object.defineProperty(Tracker.prototype, "setUserID", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userID) {
            this.userId = userID;
        }
    });
    Object.defineProperty(Tracker.prototype, "setTagClient", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tagClient) {
            this.tagClient = tagClient;
        }
    });
    Object.defineProperty(Tracker.prototype, "setReferrer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.cloudFunctionClient.setReferrer();
        }
    });
    Object.defineProperty(Tracker.prototype, "setInitialReferrer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var lpMessageReferre = this.storage.loadLpMessageReferrer;
            if (lpMessageReferre) {
                this.cloudFunctionClient.setInitialReferrer(lpMessageReferre);
            }
        }
    });
    Object.defineProperty(Tracker.prototype, "trackView", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.cloudFunctionClient.trackView(this.userId, this.session);
            this.gaClient.trackView();
            this.tagClient.trackView();
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.tagClient.trackClick(params.category, params.label, params.value);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(Tracker.prototype, "trackSlideEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.tagClient.trackSlide(params.category, params.label, params.value);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(Tracker.prototype, "trackSignUp", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, email) {
            this.gaClient.trackSignup();
            this.cloudFunctionClient.trackSignUp(userId, email);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackSignIn", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, email, authMethod) {
            this.cloudFunctionClient.trackSignIn(userId, email, authMethod);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackCCRegistered", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, usrPayment, campaignCode, reloclubKid) {
            var params = {
                cardLastFour: usrPayment.cardLastFour,
                subPlanId: usrPayment.subPlanId,
                curPeriodEndTime: usrPayment.curPeriodEndTime,
                trialEndTime: usrPayment.trialEndTime || 0,
                campaignCode: campaignCode || '',
                reloclubKid: reloclubKid || '',
            };
            this.cloudFunctionClient.ccRegister(userId, params);
            this.gaClient.trackConversion();
            this.gaClient.trackConversionGTM();
        }
    });
    Object.defineProperty(Tracker.prototype, "trackPixelEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (eventName) {
            if (!this.config.isProdOrStg)
                return;
            var cookies = new Cookies();
            var eventID = randomString(10);
            var params = {
                eventID: eventID,
                eventName: eventName,
                clickID: cookies.get('_fbc'),
                browserID: cookies.get('_fbp'),
                clientUserAgent: getUserAgent(),
                actionSource: 'website',
                eventSourceUrl: document.URL,
            };
            this.fbPixelClient.trackEvent(eventName, eventID);
            this.lambdaFunctionClient.postConversionToFB(params);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickPickup", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pickupID) {
            this.cloudFunctionClient.trackClickPickup(pickupID);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackFirstMoviePlayEvent", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.gaClient.trackFirstMoviePlayEvent();
        }
    });
    Object.defineProperty(Tracker.prototype, "trackBecomeDormant", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.cloudFunctionClient.trackBecomeDormant();
        }
    });
    Object.defineProperty(Tracker.prototype, "trackStartLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson, lessonParent) {
            this.playHistory.push(lesson);
            var params = this.compileLessonMovieEventParams(lesson, 0, lessonParent);
            this.cloudFunctionClient.trackVideoPlay(this.userId, params);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackSuspendLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, lesson, time, lessonParent) {
            var params = this.compileLessonMovieEventParams(lesson, 0, lessonParent);
            this.cloudFunctionClient.trackVideoStop(userId, params, time);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackQuitLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson, time, stats, userId, lessonParent, isAutoPlay) {
            var params = this.compileLessonMovieEventParams(lesson, time, lessonParent, stats, false);
            this.cloudFunctionClient.trackVideoPlayStats(userId, isAutoPlay, params);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackCompleteLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson, stats, userId, lessonParent, isAutoPlay) {
            var params = this.compileLessonMovieEventParams(lesson, stats.duration, lessonParent, stats, true);
            this.cloudFunctionClient.trackVideoPlayStats(userId, isAutoPlay, params);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackStartLiveLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, liveLessonId, playerUrl) {
            var params = {
                liveLessonId: liveLessonId,
                movieId: playerUrl,
                playSec: 0,
            };
            this.cloudFunctionClient.trackVideoPlay(userId, params);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackQuitLiveLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userId, liveLessonId, playerUrl, session) {
            var params = {
                liveLessonId: liveLessonId,
                movieId: playerUrl,
                playSec: session.dedupedplayDuration,
                isFullPlay: session.completed,
                playDuration: session.playDuration,
                dedupedPlayDuration: session.dedupedplayDuration,
                playDurationRatio: session.playDurationRatio,
            };
            this.cloudFunctionClient.trackVideoPlayStats(userId, false, params);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickLessonCard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson, sectionId) {
            if (sectionId === void 0) { sectionId = ''; }
            this.cloudFunctionClient.trackClickLessonCard(lesson, sectionId);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackFavoriteLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson, status) {
            this.cloudFunctionClient.trackFavoriteLesson(lesson.id, status);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackFavoriteProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId, status) {
            this.cloudFunctionClient.trackFavoriteProgram(programId, status);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackDiagnosisAnswer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (questionID, answerID) {
            this.cloudFunctionClient.trackDiagnosisAnswer(questionID, answerID);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickSearchPartCard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tagId) {
            this.cloudFunctionClient.trackClickSearchPartCard(tagId);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickSearchStyleCard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (styleID) {
            this.cloudFunctionClient.trackClickSearchStyleCard(styleID);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackSubmitSearch", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (word) {
            this.cloudFunctionClient.trackSubmitSearch(word);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickSearchResultProgram", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programID) {
            this.cloudFunctionClient.trackClickSearchResultProgram(programID);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickSearchResultLesson", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lessonID) {
            this.cloudFunctionClient.trackClickSearchResultLesson(lessonID);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackHeroClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (linkTo) {
            this.cloudFunctionClient.trackHeroClick(linkTo);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackHeroPlayClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (linkTo) {
            this.cloudFunctionClient.trackHeroPlayClick(linkTo);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackHeroDetailClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (linkTo) {
            this.cloudFunctionClient.trackHeroDetailClick(linkTo);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackAbTestViews", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (abType, abSlug) {
            this.cloudFunctionClient.trackAbTestViews(abType, abSlug);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackAbTestClicks", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (abType, abSlug, clickType, contentId) {
            this.cloudFunctionClient.trackAbTestClicks(abType, abSlug, clickType, contentId);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickMatchingAssessmentResult", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (clickType) {
            this.cloudFunctionClient.trackClickMatchingAssessmentResult(clickType);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackReceivedBadge", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (badgeID, badgeName, badgeType) {
            this.cloudFunctionClient.trackReceivedBadge(badgeID, badgeName, badgeType);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickMatchingAssessmentStart", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.cloudFunctionClient.trackClickMatchingAssessmentStart();
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickMatchingAssessmentChoices", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (assessments) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    assessments.forEach(function (choices) {
                        choices.forEach(function (choice) {
                            _this.cloudFunctionClient.trackClickMatchingAssessmentChoice(choice.id);
                        });
                    });
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickMatchingAssessmentNext", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (questionNo) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.cloudFunctionClient.trackClickMatchingAssessmentNext(questionNo);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickMatchingAssessmentComplete", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.cloudFunctionClient.trackClickMatchingAssessmentComplete();
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickChallengeStart", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId, start, end) {
            this.cloudFunctionClient.trackClickChallengeStart(programId, start, end);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickUpdateChallengeStatus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (programId, status, start, end) {
            this.cloudFunctionClient.trackClickUpdateChallengeStatus(programId, status, start, end);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackClickPlanChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (plan, me) {
            this.cloudFunctionClient.trackClickPlanChange(plan, me);
        }
    });
    // NOTE: 現在適用中のプランをQueuedPlanとして登録し、プラン変更が取り消された履歴を表現する.
    Object.defineProperty(Tracker.prototype, "trackClickRemovePlan", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (me) {
            this.cloudFunctionClient.trackClickRemovePlan(me);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackHomeBannerClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (banner) {
            this.cloudFunctionClient.trackHomeBannerClick(banner);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackPlayLiveVideo", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (liveUrl, liveTitle, startPositionTime) {
            this.cloudFunctionClient.trackPlayLiveVideo(liveUrl, liveTitle, startPositionTime);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackOriginalPlaylistBannerClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (pageName) {
            this.cloudFunctionClient.trackOriginalPlaylistBannerClick(pageName);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackOriginalPlaylistCreate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.cloudFunctionClient.trackOriginalPlaylistCreate();
        }
    });
    Object.defineProperty(Tracker.prototype, "trackFirstViewCarouselClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (linkPath) {
            this.cloudFunctionClient.trackFirstViewCarouselClick(linkPath);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackFirstViewBannerClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (linkPath) {
            this.cloudFunctionClient.trackFirstViewBannerClick(linkPath);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackHomeClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (name) {
            this.cloudFunctionClient.trackHomeClick(name);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackInvitaionLinkCopy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.cloudFunctionClient.trackInvitaionLinkCopy();
        }
    });
    Object.defineProperty(Tracker.prototype, "trackTwitterDialogOpen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (snsParams) {
            snsParams = __assign(__assign({}, snsParams), { snsProvider: SnsProvider.Twitter, userAction: 'dialog-open' });
            return this.cloudFunctionClient.trackSnsShareClick(snsParams);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackTwitterShareClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (snsParams, isAuthorized) {
            snsParams = __assign(__assign({}, snsParams), { snsProvider: SnsProvider.Twitter, userAction: 'click-share', isAuthorized: isAuthorized });
            return this.cloudFunctionClient.trackSnsShareClick(snsParams);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackTwitterPostTweetClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (snsParams) {
            snsParams = __assign(__assign({}, snsParams), { snsProvider: SnsProvider.Twitter, userAction: 'post-tweet', isAuthorized: true });
            return this.cloudFunctionClient.trackSnsShareClick(snsParams);
        }
    });
    Object.defineProperty(Tracker.prototype, "trackMypageProfileUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (profile) {
            return this.cloudFunctionClient.trackMypageProfileUpdate(profile);
        }
    });
    Object.defineProperty(Tracker.prototype, "compileLessonMovieEventParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (lesson, time, lessonParent, videoStats, isFullPlay) {
            var params = __assign({ lessonId: lesson.id, movieId: lesson.videoId, playSec: time }, videoStats);
            if (lessonParent) {
                var playlist = lessonParent;
                if (playlist.isOriginalPlaylist) {
                    params.originalPlaylistId = playlist.id;
                }
                else {
                    params.programId = playlist.id;
                }
            }
            if (isFullPlay !== undefined) {
                params.isFullPlay = isFullPlay;
            }
            return params;
        }
    });
    Object.defineProperty(Tracker.prototype, "setSession", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var sid = this.sessionStorage.loadSession();
            if (!sid) {
                sid = this.sessionStorage.setupSession();
            }
            this.session = sid.session_id;
        }
    });
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], Tracker.prototype, "trackOriginalPlaylistBannerClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], Tracker.prototype, "trackOriginalPlaylistCreate", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], Tracker.prototype, "trackFirstViewCarouselClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], Tracker.prototype, "trackFirstViewBannerClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], Tracker.prototype, "trackHomeClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", Promise)
    ], Tracker.prototype, "trackInvitaionLinkCopy", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Promise)
    ], Tracker.prototype, "trackTwitterDialogOpen", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, Boolean]),
        __metadata("design:returntype", Promise)
    ], Tracker.prototype, "trackTwitterShareClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", Promise)
    ], Tracker.prototype, "trackTwitterPostTweetClick", null);
    __decorate([
        bind,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [UserProfile]),
        __metadata("design:returntype", Promise)
    ], Tracker.prototype, "trackMypageProfileUpdate", null);
    return Tracker;
}());
export { Tracker };
