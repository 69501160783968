var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import CopyText from 'react-copy-text';
import { Card } from '@lean-body/components/atoms/card';
import { SectionHead } from '@lean-body/components/atoms/section_head';
import { Colors, MediaBreakPointUp } from '@lean-body/components/styles';
import { useXlMediaQuery } from '@lean-body/src/util/media_query_hooks';
import { routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router';
import { isNativeApp } from '@lean-body/src/util';
import { INVALID_PLAN_FOR_FRIEND_INVITE } from '@lean-body/src/messages';
var HASHTAG = 'リーンボディお友達招待';
export var InvitationPage = function (_a) {
    var apiClient = _a.apiClient, tracker = _a.tracker, alertPresenter = _a.alertPresenter, config = _a.config, accountService = _a.accountService, userInvitationService = _a.userInvitationService;
    var navigate = useNavigate();
    var isXL = useXlMediaQuery();
    var _b = React.useState(), friendInvitationCampaign = _b[0], setFriendInvitationCampaign = _b[1];
    var _c = React.useState(), invitationLink = _c[0], setInvitationLink = _c[1];
    var _d = React.useState(), isLinkCopied = _d[0], setIsLinkCopied = _d[1];
    var _e = React.useState(''), copiedText = _e[0], setCopiedText = _e[1];
    var _f = React.useState(''), amazonGiftPriceStr = _f[0], setAmazonGiftPriceStr = _f[1];
    var _g = React.useState(), snsShareText = _g[0], setSnsShareText = _g[1];
    var _h = React.useState(), encodedInvitationLink = _h[0], setEncodedInvitationLink = _h[1];
    React.useEffect(function () {
        accountService.fetchMe().then(function (me) {
            if (!userInvitationService.invitationAvailable(me.payment)) {
                alertPresenter.showMessage(INVALID_PLAN_FOR_FRIEND_INVITE);
                navigate(routes.TOP.compile());
                return;
            }
            Promise.all([apiClient.fetchInvitationCode(), apiClient.getFriendInvitationCampaign()]).then(function (_a) {
                var invitation = _a[0], friendInvitationCampaign = _a[1];
                setFriendInvitationCampaign(friendInvitationCampaign);
                // 紹介側がプラン特典受けられるようにするための招待コード
                var queryString = "?invitation_code=".concat(invitation.invitationCode, "&utm_souce=friend-invitation");
                var link = "".concat(config.lpUrl, "/campaign/friend_invitation_new/index.html").concat(queryString);
                setInvitationLink(link);
                setEncodedInvitationLink(encodeURIComponent(link));
                var giftPriceStr = friendInvitationCampaign ? friendInvitationCampaign.amazonGiftPrice.toLocaleString() : '';
                setAmazonGiftPriceStr(giftPriceStr);
                setSnsShareText((friendInvitationCampaign === null || friendInvitationCampaign === void 0 ? void 0 : friendInvitationCampaign.isHolding)
                    ? "\u3010".concat(friendInvitationCampaign === null || friendInvitationCampaign === void 0 ? void 0 : friendInvitationCampaign.endAtStr, "\u307E\u3067\u3011\u304A\u3046\u3061\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9LEAN BODY\u3092\u59CB\u3081\u3066\u3001Amazon\u30AE\u30D5\u30C8\u5238").concat(giftPriceStr, "\u5186\u5206GET\uFF01\u304A\u53CB\u9054\u7D39\u4ECB\u3067\u3055\u3089\u306B ").concat(giftPriceStr, "\u5186\uFF01")
                    : "\u304A\u3046\u3061\u30D5\u30A3\u30C3\u30C8\u30CD\u30B9LEAN BODY\u3092\u59CB\u3081\u3066\u3001Amazon\u30AE\u30D5\u30C8\u5238".concat(giftPriceStr, "\u5186\u5206GET\uFF01\u304A\u53CB\u9054\u7D39\u4ECB\u3067\u3055\u3089\u306B+").concat(giftPriceStr, "\u5186\uFF01"));
            });
        });
    }, []);
    var onClickCopy = function () {
        setCopiedText(invitationLink);
        setIsLinkCopied(true);
        tracker.trackInvitaionLinkCopy();
    };
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.contents) },
            React.createElement("div", { className: css(styles.header) }, "\u304A\u53CB\u3060\u3061\u7D39\u4ECB\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3"),
            React.createElement(Card, { className: css(styles.card) },
                React.createElement(SectionHead, { classObject: styles.sectionTitle }, "\u304A\u53CB\u3060\u3061\u7D39\u4ECB\u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u3068\u306F\uFF1F"),
                React.createElement("div", { className: css(styles.description) }, "\u304A\u53CB\u3060\u3061\u306BLEAN BODY\u3092\u3054\u7D39\u4ECB\u3044\u305F\u3060\u304F\u3068\u3001\u3054\u7D39\u4ECB\u8005\u3068\u304A\u53CB\u3060\u3061\u306B\u304A\u30C8\u30AF\u306A\u30D7\u30EC\u30BC\u30F3\u30C8\uFF01"),
                React.createElement("div", { className: css(styles.benefitContainer) },
                    (friendInvitationCampaign === null || friendInvitationCampaign === void 0 ? void 0 : friendInvitationCampaign.isHolding) && (React.createElement("div", { className: css(styles.benefit, styles.benefitFlex) },
                        React.createElement("div", { className: css(styles.benefitFirstTextLabel) }, "\u671F\u9593\u9650\u5B9A\uFF01"),
                        React.createElement("div", { className: css(styles.benefitFirstText) }, friendInvitationCampaign.periodStr))),
                    React.createElement("div", { className: css(styles.benefit) },
                        React.createElement("div", { className: css(styles.benefitFirstText) },
                            React.createElement("div", { className: css(styles.benefitFirstTextMin) }, "\u304A\u624B\u7D9A\u304D\u4E0D\u8981 / \u4F55\u56DE\u3067\u3082\u62DB\u5F85OK"),
                            React.createElement("img", { src: "assets/images/invitation/amazon_gift.png", className: css(styles.amazonImg) }),
                            "\u3054\u7D39\u4ECB\u8005\u3068\u304A\u53CB\u3060\u3061",
                            isXL ? ('にもれなく') : (React.createElement(React.Fragment, null,
                                "\u3001",
                                React.createElement("br", null))),
                            React.createElement("span", { className: css(styles.benefitFirstTextBold) },
                                amazonGiftPriceStr,
                                "\u5186\u5206"),
                            "\u305A\u3064\u30D7\u30EC\u30BC\u30F3\u30C8\uFF01"))),
                React.createElement("div", { className: css(styles.benefitAttention) },
                    "\u30AE\u30D5\u30C8\u5238\u306F\u3001\u3054\u7D39\u4ECB\u6708\u306E\u7FCC\u670810\u65E5\u307E\u3067\u306B\u3054\u767B\u9332\u3044\u305F\u3060\u3044\u305F\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3078\u9001\u4ED8\u3044\u305F\u3057\u307E\u3059\u3002",
                    React.createElement("br", null),
                    "LINE\u3001facebook\u7B49\u306E\u30BD\u30FC\u30B7\u30E3\u30B7\u30E3\u30EB\u30ED\u30B0\u30A4\u30F3\u3092\u5229\u7528\u3057\u3066\u3001\u4F1A\u54E1\u767B\u9332\u3055\u308C\u305F\u65B9\u306F\u30E1\u30FC\u30EB\u3092\u304A\u9001\u308A\u3067\u304D\u306A\u3044\u5834\u5408\u304C\u3054\u3056\u3044\u307E\u3059\u3002\u30DE\u30A4\u30A2\u30AB\u30A6\u30F3\u30C8\u304B\u3089\u3054\u767B\u9332\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u3054\u78BA\u8A8D\u306E\u4E0A\u3001\u53D7\u4FE1\u53EF\u80FD\u306A\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3078\u5909\u66F4\u3092\u304A\u9858\u3044\u3044\u305F\u3057\u307E\u3059\u3002"),
                React.createElement("div", { className: css(styles.sarani) },
                    React.createElement("div", { className: css(styles.cross) }),
                    "\u3055\u3089\u306B"),
                React.createElement("div", { className: css(styles.benefitContainer) },
                    React.createElement("div", { className: css(styles.benefit, isXL && styles.benefitFlexAlignTop) },
                        React.createElement("div", { className: css(styles.benefitSecondTextLabel) }, "\u3054\u7D39\u4ECB\u8005"),
                        React.createElement("div", { className: css(styles.benefitSecondTextContainer) },
                            React.createElement("div", { className: css(styles.benefitSecondText) },
                                React.createElement("div", { className: css(styles.benefitSecondTitle) },
                                    React.createElement("span", { className: css(styles.benefitSecondTitleBackground) }, "\u6708\u984D\u30D7\u30E9\u30F3\u3054\u5229\u7528\u306E\u65B9"),
                                    "\uFF1A"),
                                React.createElement("div", { className: css(styles.benefitSecondDesc) }, "\u7FCC\u6708\u306E\u30B5\u30FC\u30D3\u30B9\u5229\u7528\u6599\u3092\uFFE50\u3068\u3055\u305B\u3066\u3044\u305F\u3060\u304D\u307E\u3059\uFF01")),
                            React.createElement("div", { className: css(styles.benefitSecondText) },
                                React.createElement("div", { className: css(styles.benefitSecondTitle) },
                                    React.createElement("span", { className: css(styles.benefitSecondTitleBackground) }, "\u5E74\u9593\u30D7\u30E9\u30F3\u3054\u5229\u7528\u306E\u65B9"),
                                    "\uFF1A"),
                                React.createElement("div", { className: css(styles.benefitSecondDesc) }, "\u6B21\u56DE\u66F4\u65B0\u304B\u308912\u30F6\u6708\u30D7\u30E9\u30F3\u309250\uFF05OFF\uFF08\u6708\u984D1,078\u5186\u76F8\u5F53\uFF09\u3067\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\uFF01")))),
                    React.createElement("div", { className: css(styles.benefit, isXL && styles.benefitFlex) },
                        React.createElement("div", { className: css(styles.benefitSecondTextLabel) }, "\u304A\u53CB\u3060\u3061"),
                        React.createElement("div", { className: css(styles.benefitSecondTextContainer) },
                            React.createElement("div", { className: css(styles.benefitSecondDesc) }, "\u7D39\u4ECB\u30EA\u30F3\u30AF\u304B\u3089\u306E\u5165\u4F1A\u3067\u300112\u30F6\u6708\u30D7\u30E9\u30F3\u309250\uFF05OFF\uFF08\u6708\u984D1,078\u5186\u76F8\u5F53\uFF09\u3067\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u307E\u3059\uFF01")))),
                React.createElement("div", { className: css(styles.benefitAttention) },
                    "\u203B iOS\u30A2\u30D7\u30EA\u304B\u3089\u4F1A\u54E1\u767B\u9332\u3055\u308C\u305F\u65B9 / \u9000\u4F1A\u624B\u7D9A\u304D\u3092\u3055\u308C\u305F\u65B9\u306F\u30D7\u30E9\u30F3\u306E\u5272\u5F15\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059\u306E\u3067\u3054\u4E86\u627F\u304F\u3060\u3055\u3044",
                    React.createElement("br", null),
                    "\u203B \u7279\u5225\u30AD\u30E3\u30F3\u30DA\u30FC\u30F312\u30F6\u6708\u30D7\u30E9\u30F3\u3067\u4F1A\u54E1\u767B\u9332\u3055\u308C\u305F\u65B9\u306F\u30D7\u30E9\u30F3\u306E\u5272\u5F15\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059\u306E\u3067\u3054\u4E86\u627F\u304F\u3060\u3055\u3044",
                    React.createElement("br", null),
                    "\u203B \u62DB\u5F85\u7279\u5178\u306F\u62DB\u5F85\u3055\u308C\u305F\u65B9\u304C\u652F\u6255\u3044\u60C5\u5831\u3092\u767B\u9332\u3057\u305F\u6642\u70B9\u3067\u9069\u7528\u3055\u308C\u307E\u3059",
                    React.createElement("br", null),
                    "\u203B \u30E1\u30AC\u30ED\u30B9 LEAN BODY\u3001\u798F\u5229\u539A\u751F\u30D7\u30E9\u30F3\u3001WECLE\u4F1A\u54E1\u30D7\u30E9\u30F3\u3001\u7279\u5B9A\u4FDD\u5065\u6307\u5C0E\u30D7\u30E9\u30F3\u3092\u3054\u5229\u7528\u306E\u65B9\u306F\u5BFE\u8C61\u5916\u3068\u306A\u308A\u307E\u3059",
                    React.createElement("br", null),
                    "\u203B \u30AD\u30E3\u30F3\u30DA\u30FC\u30F3\u306F\u544A\u77E5\u306A\u304F\u7D42\u4E86\u3055\u305B\u3066\u3044\u305F\u3060\u304F\u53EF\u80FD\u6027\u304C\u3054\u3056\u3044\u307E\u3059")),
            React.createElement(Card, { isCentered: true, className: css(styles.card) },
                React.createElement(SectionHead, { classObject: styles.sectionTitle }, "\u3042\u306A\u305F\u306E\u62DB\u5F85\u30EA\u30F3\u30AF"),
                React.createElement("div", { className: css(styles.copyLinkDescription) }, "\u3053\u3061\u3089\u306E\u30EA\u30F3\u30AF\u3092\u304A\u53CB\u3060\u3061\u306B\u5171\u6709\u3057\u3066\u304F\u3060\u3055\u3044"),
                React.createElement("div", { className: css(styles.copyContainer) },
                    React.createElement(CopyText, { text: copiedText }),
                    React.createElement("div", { className: css(styles.copyLink), onClick: onClickCopy }, invitationLink),
                    React.createElement("div", { onClick: onClickCopy, className: css(styles.tapToCopy, isLinkCopied && styles.copied) }, isLinkCopied ? 'コピー済' : 'リンクをコピーする')),
                !isNativeApp() && (React.createElement("div", { className: css(styles.btnGroup) },
                    React.createElement("a", { href: "https://social-plugins.line.me/lineit/share?url=".concat(encodedInvitationLink, "&text=").concat(snsShareText), target: "_blank", className: css(styles.link, styles.linkLine) }, "LINE\u3067\u5171\u6709"),
                    React.createElement("a", { href: "https://www.facebook.com/sharer/sharer.php?u=".concat(encodedInvitationLink, "&hashtag=%23").concat(HASHTAG), target: "_blank", className: css(styles.link, styles.linkFacebook) }, "Facebook\u3067\u5171\u6709"),
                    React.createElement("a", { href: "http://twitter.com/share?url=".concat(encodedInvitationLink, "&text=").concat(snsShareText, "%0A%23").concat(HASHTAG, "%0A"), target: "_blank", className: css(styles.link, styles.linkTwitter) }, "X\u3067\u5171\u6709")))))));
};
var styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.BackgroundLightGray,
    },
    contents: (_a = {
            padding: '24px 12px 48px',
            position: 'relative',
            margin: '0 auto',
            maxWidth: '850px'
        },
        _a[MediaBreakPointUp.MD] = {
            width: '70%',
            padding: '32px 0 48px',
        },
        _a),
    card: (_b = {
            marginBottom: 24,
            padding: '24px 16px'
        },
        _b[MediaBreakPointUp.MD] = {
            padding: 32,
        },
        _b),
    description: (_c = {
            fontWeight: 'bold',
            margin: '16px 0',
            lineHeight: '25px'
        },
        _c[MediaBreakPointUp.XL] = {
            textAlign: 'center',
        },
        _c),
    benefitContainer: {
        backgroundColor: Colors.BackgroundLightGray,
        padding: 24,
        maxWidth: 666,
        margin: '0 auto',
    },
    benefit: {
        ':not(:last-of-type)': {
            position: 'relative',
            marginBottom: 33,
            ':after': {
                position: 'absolute',
                content: '""',
                height: 1,
                width: '100%',
                bottom: -16,
                backgroundColor: Colors.Gray,
            },
        },
    },
    benefitFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    benefitFlexAlignTop: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    benefitFirstTextLabel: {
        color: Colors.White,
        fontSize: 14,
        fontWeight: 'bold',
        padding: '6px 20px',
        marginRight: 10,
        textAlign: 'center',
        backgroundColor: Colors.Accent,
        flex: 1,
        maxWidth: 120,
    },
    benefitFirstText: {
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        letterSpacing: '0.04em',
    },
    benefitFirstTextBold: (_d = {
            fontSize: 24,
            lineHeight: '33px'
        },
        _d[MediaBreakPointUp.XL] = {
            fontSize: 32,
            lineHeight: '44px',
        },
        _d),
    benefitFirstTextMin: {
        fontSize: 13,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ':before': {
            display: 'block',
            content: '""',
            width: 2,
            height: 14,
            transform: 'rotate(-22deg)',
            marginRight: 12,
            backgroundColor: Colors.Black,
        },
        ':after': {
            display: 'block',
            content: '""',
            width: 2,
            height: 14,
            transform: 'rotate(22deg)',
            marginLeft: 12,
            backgroundColor: Colors.Black,
        },
    },
    benefitSecondTextLabel: (_e = {
            display: 'inline-block',
            padding: 10,
            fontWeight: 'bold',
            color: Colors.White,
            borderRadius: 8,
            margin: '0 0 16px 0',
            backgroundColor: Colors.PrimaryDark
        },
        _e[MediaBreakPointUp.XL] = {
            margin: '0 16px 0 0',
        },
        _e),
    benefitSecondTextContainer: {
        flex: 1,
    },
    benefitSecondText: {
        ':not(:last-of-type)': {
            marginBottom: 16,
        },
    },
    benefitSecondTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        position: 'relative',
        marginBottom: 4,
        display: 'inline-block',
    },
    benefitSecondTitleBackground: {
        background: "linear-gradient(transparent 60%, ".concat(Colors.BlueLighterB, " 40%)"),
    },
    benefitSecondDesc: {
        fontSize: 14,
    },
    benefitAttention: {
        fontSize: 12,
        lineHeight: '160%',
        margin: '16px auto 0',
        color: Colors.Black,
        maxWidth: 666,
    },
    benefitCaution: {
        fontSize: 12,
        marginTop: 8,
    },
    sarani: {
        margin: '28px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontWeight: 'bold',
    },
    cross: {
        position: 'relative',
        width: 5,
        height: 20,
        background: Colors.Black,
        marginRight: 18,
        lineHeight: 1,
        ':after': {
            content: '""',
            position: 'absolute',
            top: 7.5,
            left: -7.5,
            width: 20,
            height: 5,
            background: Colors.Black,
        },
    },
    btnGroup: (_f = {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center'
        },
        _f[MediaBreakPointUp.XL] = {
            flexFlow: 'row nowrap',
            justifyContent: 'space-between',
        },
        _f),
    link: (_g = {
            color: Colors.White,
            display: 'block',
            width: '100%',
            transition: 'all .6s ease',
            borderRadius: '4px',
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 1,
            textAlign: 'center',
            padding: '10px 0'
        },
        _g[MediaBreakPointUp.XL] = {
            fontSize: 16,
            maxWidth: '250px',
            width: '32%',
        },
        _g[':not(:last-of-type)'] = (_h = {
                marginBottom: 16
            },
            _h[MediaBreakPointUp.XL] = {
                marginBottom: 0,
            },
            _h),
        _g[':hover'] = {
            opacity: 0.8,
        },
        _g),
    linkLine: {
        backgroundColor: Colors.Line,
    },
    linkFacebook: {
        backgroundColor: Colors.Facebook,
    },
    linkTwitter: {
        backgroundColor: Colors.StrictBlack,
    },
    copyLinkDescription: (_j = {
            fontSize: 14,
            margin: '16px 0'
        },
        _j[MediaBreakPointUp.MD] = {
            margin: '18px 0',
        },
        _j),
    copyContainer: (_k = {
            marginBottom: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        _k[MediaBreakPointUp.XL] = {
            flexDirection: 'row',
        },
        _k),
    copyLink: (_l = {
            fontSize: 12,
            fontWeight: 'bold',
            padding: 16,
            maxWidth: 454,
            backgroundColor: Colors.BackgroundLightGray,
            margin: '0 0 16px 0',
            cursor: 'pointer',
            textAlign: 'left'
        },
        _l[MediaBreakPointUp.XL] = {
            fontSize: 14,
            margin: '0 16px 0 0',
        },
        _l),
    tapToCopy: {
        color: Colors.GrayDarkest,
        cursor: 'pointer',
        fontSize: 14,
        padding: 12,
        width: 155,
        border: "1px solid ".concat(Colors.GrayDarkest),
        borderRadius: 4,
    },
    copied: {
        backgroundColor: Colors.GrayDarkest,
        color: Colors.White,
    },
    header: (_m = {
            fontSize: 24,
            fontWeight: 'bold',
            lineHeight: '1.25',
            marginBottom: 9
        },
        _m[MediaBreakPointUp.MD] = {
            margin: '16px 0 24px',
        },
        _m[MediaBreakPointUp.XL] = {
            fontSize: 28,
        },
        _m),
    sectionTitle: {
        fontSize: 18,
        textAlign: 'center',
    },
    amazonImg: {
        display: 'block',
        width: 170,
        margin: '0 auto 16px',
    },
});
