var _a;
import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import * as msg from '@lean-body/src/messages';
import { Button, ButtonType } from '@lean-body/components/atoms';
import { MediaBreakPointUp } from '@lean-body/components/styles';
import { routes } from '@lean-body/src/route';
import { Link } from 'react-router-dom';
import { LBError } from '@lean-body/src/errors';
import { dateToYearMonthDayString } from '@lean-body/src/util';
export var CoachingPlanUnsubscribeSubsection = function (_a) {
    var coachingPayment = _a.coachingPayment, apiClient = _a.apiClient, alertPresenter = _a.alertPresenter;
    var _b = React.useState(false), submitting = _b[0], setSubmitting = _b[1];
    var _c = React.useState(false), completed = _c[0], setCompleted = _c[1];
    var onSubmit = function () {
        setSubmitting(true);
        apiClient
            .deleteCoachingPayment()
            .then(function () {
            setSubmitting(false);
            setCompleted(true);
        })
            .catch(function () {
            alertPresenter.showError(new LBError(), msg.UNSUBSCRIBE_COACHING_PLAN_FAILED);
            setSubmitting(false);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: css(styles.head) }, "\u30D7\u30E9\u30F3\u505C\u6B62\u624B\u7D9A\u304D"),
        completed ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.title) }, "\u30D7\u30E9\u30F3\u505C\u6B62\u51E6\u7406\u304C\u5B8C\u4E86\u3057\u307E\u3057\u305F"),
            React.createElement("div", { className: css(styles.description) },
                "\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u3092\u518D\u958B\u3059\u308B\u5834\u5408\u306F",
                dateToYearMonthDayString(coachingPayment.curPeriodEndDate),
                "\u4EE5\u964D\u306BHOME>\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u304B\u3089\u518D\u5EA6\u30D7\u30E9\u30F3\u767B\u9332\u3092\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
            React.createElement(Link, { to: routes.TOP.compile() },
                React.createElement(Button, { className: css(styles.button), buttonType: ButtonType.primary }, "HOME\u306B\u623B\u308B")))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.title) }, "\u30B3\u30FC\u30C1\u30F3\u30B0\u30D7\u30E9\u30F3\u3092\u505C\u6B62\u3057\u3066\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F"),
            React.createElement("div", { className: css(styles.description) },
                dateToYearMonthDayString(coachingPayment.curPeriodEndDate),
                "\u4EE5\u964D\u3001\u30B3\u30FC\u30C1\u30F3\u30B0\u6A5F\u80FD\u3092\u3054\u5229\u7528\u3044\u305F\u3060\u3051\u306A\u304F\u306A\u308A\u307E\u3059\u3002"),
            React.createElement("div", { className: css(styles.buttonBox) },
                React.createElement(Button, { className: css(styles.button), buttonType: ButtonType.alert, onClick: onSubmit, loading: submitting, disabled: submitting }, "\u505C\u6B62\u3059\u308B"),
                React.createElement(Link, { to: routes.TOP.compile() },
                    React.createElement(Button, { className: css(styles.button), buttonType: ButtonType.whiteWithBlack }, "HOME\u306B\u623B\u308B")))))));
};
var styles = StyleSheet.create({
    head: {
        textAlign: 'start',
        fontSize: 18,
        fontWeight: 'bold',
        paddingTop: 40,
        marginBottom: 24,
        lineHeight: '140%',
        letterSpacing: '1.44px',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 40,
        lineHeight: '160%',
        letterSpacing: '1.28px',
    },
    description: {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 40,
        lineHeight: '140%',
        letterSpacing: '0.56px',
    },
    buttonBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    button: (_a = {
            width: '100%'
        },
        _a[MediaBreakPointUp.MD] = {
            maxWidth: 315,
            margin: '0 auto',
        },
        _a),
});
